import React from "react";
// Customizable Area Start
import { Box, Grid, styled, Typography, Button, InputBase, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from "@mui/material";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import {ArrowForwardIos, ArrowDropUp, ArrowDropDown, MoreVert } from '@mui/icons-material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { activeMainDashboard, activeMainSetting, activeMainAccount, activeMainOrder, activeMainVendor, activeMainReminder, activePropertyMain, activeMainFinancial, activeMainReport, keyMainChain, activeKeyMain, settingsMain, keasyIcon, accountsMain, dashboardLandLogo, financialMain, ordersMain, propertyMain, remindersMain, reportMain, vendorMain, bottomImageIcon, plus, SearchBarIcon, groupBlocks, previousButton, nextButton, eyeIcon } from "./assets";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderEmptyDetails = () => {
    return (
      <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} height={"100%"} gap={2}>
        <NothingSee>{webConfigJSON.nothing}</NothingSee>
        <EmptyDetails>{webConfigJSON.nothingText}</EmptyDetails>
        <StartBtn>{webConfigJSON.startButton}</StartBtn>
      </Box>
    );
  }

  renderWorkOrder = () => {
    return (
      <Box>
        <Grid container spacing={1} display={"flex"} alignItems={"center"}>
          <Grid item xs={12} md={4} justifyContent="flex-start">
            <Box className="search-main-content">
              <Box data-test-id="navigationsId" className="new-property">
                <img
                  className="icon"
                  src={plus} alt="plus" />
                <Typography className="new-data">{webConfigJSON.newWorks}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            <Box className="search-contents">
              <img
                className="search-icons"
                src={SearchBarIcon} alt="location" />
              <InputBase
                data-test-id="workSearchId"
                placeholder="Search"
                className="search-inputs"
                inputProps={{ "aria-label": "search" }}
              />
            </Box>
          </Grid>
        </Grid>
        <Box
          data-test-id="vendorsItem" mt={"1rem"}>
          <CustomTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <DetailsCell>{webConfigJSON.locations}</DetailsCell>
                  <DetailsCell>{webConfigJSON.category}</DetailsCell>
                  <DetailsCell>{webConfigJSON.workNeeded}</DetailsCell>
                  <DetailsCells>{webConfigJSON.status}</DetailsCells>
                  <TableCell />
                </TableRow>
              </TableHead>
              <CustomContainer>
                    <React.Fragment>
                      <TableRow>
                        <LocationCells data-test-id="sortId"></LocationCells>
                        <StatusCell></StatusCell>
                        <StatusCell></StatusCell>
                        <StatusCells>
                          <OrderStatus data-test-id="statusId">
                          </OrderStatus>
                        </StatusCells>
                        <ViewCell>
                          <ArrowForwardIos />
                        </ViewCell>
                      </TableRow>
                    </React.Fragment>
              </CustomContainer>
            </Table>
          </CustomTableContainer>
        </Box>
      </Box>
    )
  }

  renderSortableCells = (reminder: string) => {
    return (
      <CustomCells key={reminder}>
        <Box display="flex" flexDirection="column" marginLeft={"10px"}>
          <CustomButtonsUp
            data-test-id="sortUpId"
          >
            <ArrowDropUp
            />
          </CustomButtonsUp>
          <CustomButtonsDown
            data-test-id="downId"
          >
            <ArrowDropDown
            />
          </CustomButtonsDown>
        </Box>
      </CustomCells>
    );
  };

  renderReminders = () => {
    return (
      <Box>
        <Grid container spacing={1} display={"flex"} alignItems={"center"}>
          <Grid item xs={12} md={4} justifyContent="flex-start">
            <Box className="search-main-content">
              <Box data-test-id="reminderId" className="new-property">
                <img
                  className="icon"
                  src={plus} alt="plusIcon" />
                <Typography className="new-data">{webConfigJSON.newReminder}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            <Box className="search-contents">
              <img
                className="search-icons"
                src={SearchBarIcon} alt="locations" />
              <InputBase
                data-test-id="workSearchsId"
                inputProps={{ "aria-label": "search" }}
                placeholder="Search"
                className="search-inputs"
              />
            </Box>
          </Grid>
        </Grid>
        <Box
          data-test-id="vendorsItemId" mt={"1rem"}>
          <CustomTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <DetailsCell>{webConfigJSON.locations}</DetailsCell>
                  <DetailsCell>{webConfigJSON.category}</DetailsCell>
                  <DetailsCell>{webConfigJSON.dueDate}</DetailsCell>
                  <DetailsCells>{webConfigJSON.status}</DetailsCells>
                  <DetailsCells>{webConfigJSON.assignee}</DetailsCells>
                  {this.renderSortableCells("location")}
                </TableRow>
              </TableHead>
              <CustomContainer>
                    <React.Fragment>
                      <TableRow>
                        <LocationCells data-test-id="sortId"></LocationCells>
                        <CategoryCell></CategoryCell>
                        <StatusCell>
                          <ColoringStatus data-test-id="colorsId">
                          </ColoringStatus>
                        </StatusCell>
                        <StatusCell>
                        </StatusCell>
                        <StatusCell></StatusCell>
                        <ViewCell>
                          <IconButton data-test-id="menuIconsId">
                            <MoreVert />
                          </IconButton>
                        </ViewCell>
                      </TableRow>
                    </React.Fragment>
              </CustomContainer>
            </Table>
          </CustomTableContainer>
        </Box>
      </Box>
    );
  };

  renderPreferredVendor = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      arrows: true,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Box>
        <Grid container spacing={1} display={"flex"} alignItems={"center"}>
          <Grid item md={4} xs={12} justifyContent="flex-start">
            <Box className="search-main-content">
            <Button data-test-id="preVendorsId" className="properties-dropdown">{webConfigJSON.electricals}</Button>
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            <Box className="search-contents">
              <img
                className="search-icons"
                src={SearchBarIcon} alt="locationsSearch" />
              <InputBase
                data-test-id="workSearchsId"
                inputProps={{ "aria-label": "search" }}
                placeholder="Search"
                className="search-inputs"
              />
            </Box>
          </Grid>
        </Grid>
        <PreferredBox>
          <Box pl={"15px"} width= {"100%"} height={"90%"}>
          <CustomSlider {...settings}>
            <div>
              <ImageContainer>
                <Images src={groupBlocks} alt="profile" />
              </ImageContainer>
              <Box className="carouselText">
                <VendorName data-test-id="carouselText"></VendorName>
                <PhoneNumber data-test-id="carouselPhone">{webConfigJSON.phoneNumber}</PhoneNumber>
                <EmailId>{webConfigJSON.emailAddress}</EmailId>
                <Box className="assignVenderBox1">
                  <Box display={"flex"} justifyContent={"space-between"} width={"244px"}>
                    <Box display={"flex"} flexDirection={"column"} >
                      <WorkingHours>{webConfigJSON.workinHours}</WorkingHours>
                      <Costing>{webConfigJSON.monSaturday}</Costing>
                      <Costing>{webConfigJSON.minCost}</Costing>
                    </Box>
                    <VendorButton data-test-id="chooseVendorTest">{webConfigJSON.chooseVendor}</VendorButton>
                  </Box>
                </Box>
              </Box>
            </div>
          </CustomSlider>
          <Box data-test-id="remindersId" className="new-properties">
              <img
                className="icon"
                src={eyeIcon} alt="plusIcon" />
              <Typography className="new-data">{webConfigJSON.viewAll}</Typography>
            </Box>
          </Box>
        </PreferredBox>
      </Box>
    );
  };

  renderDeliquencyReport = () => {
    return (
      <Box>
        <TableContainer sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Table
            aria-label="custom table"
            sx={{ borderSpacing: '0 10px', borderCollapse: 'separate' }}
          >
            <TableHead>
              <TableRow>
                <StyledCells><Typography></Typography></StyledCells>
                <PaidCell><Typography></Typography></PaidCell>
                <Late30Cell><Typography></Typography></Late30Cell>
                <Late60Cell><Typography></Typography></Late60Cell>
                <Late60PlusCell><Typography></Typography></Late60PlusCell>
                <VacantCell><Typography></Typography></VacantCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <WorkOrderStyle>
        <NavBarArea>
            <NavigationSidebar
              data-test-id={"sidebarMenuId"}
              activeItem={this.state.activeMainItem}
              sidebarItems={[
                { label: webConfigJSON.dashboardLandloard, labelKey: webConfigJSON.dashboardLandlabel, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: webConfigJSON.dashboardLandlabel },
                { label: webConfigJSON.myAccountlandloard, labelKey: webConfigJSON.myAccountlandlabel, icon: accountsMain, activeIcon: activeMainAccount, pathName: webConfigJSON.myAccountlandlabel },
                { label: webConfigJSON.worklandloard, labelKey: webConfigJSON.workOrderslandlabel, icon: ordersMain, activeIcon: activeMainOrder, pathName: webConfigJSON.workOrderslandlabel },
                { label: webConfigJSON.preferredlandloard, labelKey: webConfigJSON.prevendorlandlabel, icon: vendorMain, activeIcon: activeMainVendor, pathName: webConfigJSON.prevendorlandlabel },
                { label: webConfigJSON.reminderslandloard, labelKey: webConfigJSON.reminlandlabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: webConfigJSON.reminlandlabel },
                { label: webConfigJSON.myproplandloard, labelKey: webConfigJSON.myproplandlabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: webConfigJSON.myproplandlabel },
                { label: webConfigJSON.financialandloard, labelKey: webConfigJSON.financlandlabel, icon: financialMain, activeIcon: activeMainFinancial, pathName: webConfigJSON.financlandlabel },
                { label: webConfigJSON.reportlandloard, labelKey: webConfigJSON.reportlandlabel, icon: reportMain, activeIcon: activeMainReport, pathName: webConfigJSON.reportlandlabel },
                { label: webConfigJSON.chatLandloard, labelKey: webConfigJSON.chatlandlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: webConfigJSON.chatlandlabel },
                { label: webConfigJSON.settingLandlord, labelKey: webConfigJSON.settinglandlabel, icon: settingsMain, activeIcon: activeMainSetting, pathName: webConfigJSON.settinglandlabel }
              ]}
              onClickSidebar={this.handleMainItemClick}
              keasylogo={keasyIcon}
              openDrawer={this.state.openLandlordDrawer}
              onClickDrawer={this.handleLandlordDrawer}
            />
        </NavBarArea>
          <TaskArea display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}
         >
          <MainWorkContainer>
            <WorkStyles>
              <WorkOrderHead data-test-id="seeTextId">
                {webConfigJSON.dashboardLandloard}
              </WorkOrderHead>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xl={6} lg={6} sm={12} xs={12}>
                    <BorderContainer>
                      <Headings>{webConfigJSON.deliquencyReport}</Headings>
                      {this.renderEmptyDetails()}
                    </BorderContainer>
                  </Grid>
                  <Grid item xl={6} lg={6} sm={12} xs={12}>
                    <BorderContainer>
                      <Headings>{webConfigJSON.worksOrders}</Headings>
                      {this.renderEmptyDetails()}
                    </BorderContainer>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xl={6} lg={6} sm={12} xs={12}>
                    <BorderContainer>
                      <Headings>{webConfigJSON.remindersDetails}</Headings>
                      {this.renderEmptyDetails()}
                    </BorderContainer>
                  </Grid>
                  <Grid item xl={6} lg={6} sm={12} xs={12}>
                    <BorderContainer>
                      <Headings>{webConfigJSON.preferreds}</Headings>
                      {this.renderEmptyDetails()}
                      <Box display={"none"}>
                        {this.renderDeliquencyReport()}
                        {this.renderReminders()}
                        {this.renderPreferredVendor()}
                        {this.renderWorkOrder()}
                      </Box>
                    </BorderContainer>
                  </Grid>
                </Grid>
              </Box>
            </WorkStyles>
            <Box className="bgImgWrapper" data-test-id="bgImgId">
              <Box className="backgroundImage">
                <img src={bottomImageIcon} alt="hexagon-one" className="hexImg" />
              </Box>
            </Box>
          </MainWorkContainer>
        </TaskArea>
      </WorkOrderStyle>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const WorkOrderStyle = styled(Box)({
  justifyContent: "space-between",
  width: "100%",
  display: "flex",
  fontFamily: "Outfit",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "&.webStyle": {
    fontFamily: "Outfit",
    backgroundColor: "red",
  },
  "& .bgImgWrapper": {
    justifyContent: "flex-end",
    width: "100%",
    fontFamily: "Outfit",
    marginTop: "2rem",
    display: "flex",
    "@media (max-width:992px)": {
      display: "none",
    }
  },
  "& .backgroundImage": {
    fontFamily: "Outfit",
    textAlign: "end",
    width: "100%",
    position: "absolute"
  },
  "& .hexImg": {
    objectFit: "cover"
  }
});

const NavBarArea = styled(Box)({
  position: "fixed",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 1000,
  overflowY: "auto",
  border: "1px solid rgb(208,203,199)",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    border: "none",
    overflowY: "hidden",
  }
});

const TaskArea = styled(Box)({
  overflowY: "auto",
  flexGrow: 1,
  height: "100vh",
  marginLeft: "90px",
  "@media(max-width: 992px)": {
    overflowY: "unset",
    marginLeft: "0px",
    height: "auto",
  }
});

const MainWorkContainer = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  fontFamily: "Outfit",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px 0px 0px 0px"
  }
});

const WorkOrderHead = styled(Typography)({
  color: "#000000",
  fontSize: "24px",
  fontWeight: 700,
  marginBottom: "20px",
  fontFamily: "Outfit",
});

const WorkStyles = styled(Box)({
  marginTop: "42px",
  width: "90%",
  "& .search-main-content": {
    display: "flex",
    cursor: "pointer",
    gap: "1rem",
    fontFamily: "Outfit",
    "@media(max-width:600px)": {
      width: "100%",
      flexDirection: "row",
      height: "100%",
      display: "flex",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .search-contents": {
    borderRadius: "50px",
    display: "flex",
    fontFamily: "Outfit",
    alignItems: "center",
    padding: "2px 6px 2px 6px",
    border: "1px solid #CBD5E1",
    height: "32px",
    gap: "13px",
  },
  "& .search-icons": {
    height: "24px",
    width: "24px"
  },
  "& .search-text": {
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Outfit",
    color: "#A3978F",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .search-inputs": {
    width: "100%"
  },
  "& .properties-dropdown": {
    width: "140px",
    justifyContent: "space-around",
    height: "40px",
    backgroundColor: "rgb(253,242,208)",
    textTransform: "none",
    fontFamily: "Outfit",
    borderRadius: "8px",
    padding: "2px",
    display: "flex",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      width: "105px",
      height: "30px",
      fontSize: "12px",
    }
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    width: "16px",
    height: "16px"
  },
  "& .text-data": {
    fontSize: "16px",
    fontFamily: "Outfit",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-data": {
    color: "#CC9200",
    fontSize: "16px",
    fontWeight: 700,
    whiteSpace: "nowrap",
    fontFamily: "Outfit",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-property": {
    gap: "5px",
    fontFamily: "Outfit",
    display: "flex",
    justifyContent: "flex-end",
    borderRadius: "8px",
    cursor: "pointer",
    alignItems: "center",
  },
  "& .new-vendor": {
    backgroundColor: "rgb(253,242,208)",
    textTransform: "none",
    fontFamily: "Outfit",
    display: "flex",
    gap: "5px",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)"
    }
  },
  "& .filter-content": {
    display: "flex",
    fontFamily: "Outfit",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .filter": {
    fontFamily: "Outfit",
    alignItems: "center",
    display: "flex",
    gap: "15px",
  },
  "& .new-properties": {
    gap: "5px",
    fontFamily: "Outfit",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "35px",
    cursor: "pointer",
    borderRadius: "8px",
    alignItems: "center",
  },
  "& .filter-data": {
    fontWeight: 700,
    fontFamily: "Outfit",
    fontSize: "12px",
    color: "#000000",
    whiteSpace: "nowrap"
  },
  "& .clear-data": {
    color: "#CC9200",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontWeight: 700,
    cursor: "pointer",
    whiteSpace: "nowrap"
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});

const BorderContainer = styled(Box)({
  border: "1px solid #FFD466",
  height: "650px",
  padding: "1rem 1rem 0rem 1rem",
  flexDirection: "column",
  marginTop: "1rem",
  overflow: "auto",
  borderRadius: "8px 8px 32px 8px",
  display: "flex",
  "& .scrollable-container": {
    fontFamily: "Outfit",
    height: "300px",
    overflowY: "auto"
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});

const Headings = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  fontFamily: "Outfit"
});

const NothingSee = styled(Typography)({
  fontSize: "20px",
  fontWeight: 700,
  fontFamily: "Outfit",
  color: "#CC9200"
});

const EmptyDetails = styled(Typography)({
  color: "#70645C",
  fontSize: "16px",
  fontWeight: 400,
  fontFamily: "Outfit"
});

const StartBtn = styled(Button)({
  width: "fit-content",
  height: "44px",
  fontSize: '16px',
  borderRadius: "8px",
  backgroundColor: "#FFC123",
  color: "#000000",
  textTransform: "none",
  marginRight: "25px",
  fontWeight: 700,
  cursor: "pointer",
  fontFamily: "Outfit, sans-serif",
  "&:hover": {
    backgroundColor: "#FFC123",
  },
  "@media (max-width:600px)": {
    marginRight: "0px",
  }
});

const CustomTableContainer = styled(TableContainer)({
  backgroundColor: "#FFFFFF",
  "&.isUnitsDisabled": {
    display: "none"
  }
});

const CustomContainer = styled(TableBody)({
  backgroundColor: "#FFFFFF"
});

const DetailsCell = styled(TableCell)({
  color: "#A3978F",
  fontWeight: 700,
  fontSize: "12px",
  textAlign: "center",
  whiteSpace: "nowrap",
});

const DetailsCells = styled(TableCell)({
  fontWeight: 700,
  fontSize: "12px",
  textAlign: "left",
  color: "#A3978F",
  whiteSpace: "nowrap",
});

const ViewCell = styled(TableCell)({
  whiteSpace: "nowrap",
  fontSize: "12px",
  fontFamily: "Outfit",
  fontWeight: 400,
  textAlign: "left",
  cursor: "pointer",
  color: "#000000",
});

const StatusCell = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 400,
  whiteSpace: "nowrap",
  textAlign: "center",
  fontFamily: "Outfit",
  color: "#000000",
});

const CategoryCell = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 400,
  fontFamily: "Outfit",
  textAlign: "center",
  color: "#000000",
});

const StatusCells = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 400,
  whiteSpace: "nowrap",
  textAlign: "left",
  fontFamily: "Outfit",
  color: "#000000",
});

const LocationCells = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 700,
  textAlign: "left",
  fontFamily: "Outfit",
  color: "#1C1917",
});

const OrderStatus = styled(Typography)({
  borderRadius: "50px",
  color: "#059669",
  textAlign: "center",
  fontFamily: "Outfit",
  whiteSpace: "nowrap",
  padding: "3px 8px",
  fontWeight: 700,
  fontSize: "9px",
  width: "fit-content"
});

const CustomCells = styled(TableCell)({
  color: "#A3978F",
  fontWeight: 700,
  whiteSpace: "nowrap",
  fontSize: "12px",
  padding: "0px 10px 0px 10px !important",
  textAlign: "center"
});

const CustomButtonsUp = styled(Typography)({
  marginBottom: "-14px",
  cursor: "pointer"
});

const CustomButtonsDown = styled(Typography)({
  marginTop: "0px",
  cursor: "pointer"
});

const ColoringStatus = styled(Typography)({
  color: "#000000",
  textAlign: "center",
  fontFamily: "Outfit",
  whiteSpace: "nowrap",
  fontWeight: 700,
  fontSize: "12px"
});

const PreferredBox = styled(Box)({
  marginTop: "0rem"
});

const EmailId = styled(Typography)({
  color: "#64748B",
  fontSize: "14px",
  marginTop: "5px",
  marginBottom: "10px"
});

const VendorName = styled(Typography)({
  color: "#0F172A",
  fontSize: "14px",
  fontWeight: 700,
  fontFamily: "Outfit"
});

const PhoneNumber = styled(Typography)({
  color: "#64748B",
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: "Outfit"
});

const WorkingHours = styled(Typography)({
  color: "#1C1917",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "1.1",
  fontFamily: "Outfit"
});

const Costing = styled(Typography)({
  color: "#1C1917",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "1.1",
  fontFamily: "Outfit"
});

const VendorButton = styled(Typography)({
  color: "#000000",
  fontSize: "14px",
  cursor: "pointer",
  fontWeight: 700,
  height: "fit-content",
  padding: "10px",
  fontFamily: "Outfit",
  width: "fit-content",
  backgroundColor: "#FFC123",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "#FFD700",
  },
});

const ImageContainer = styled(Box)({
  width: "244px",
  height: "224",
  borderRadius: "17.48px"
});

const Images = styled("img")({
  width: "fit-content"
});

const CustomSlider = styled(Slider)({
  "& .slick-prev": {
    position: "relative",
    left: -17,
    top: 150,
    zIndex: 1,
    border: "none",
    cursor: "pointer",
    background: "transparent",
    backgroundImage: `url(${previousButton})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "45px",
    height: "45px",
    "&::before": {
      content: '""',
      position: "fixed",
      top: "-40px",
      left: "0",
      width: "30px",
      height: "30px",
    },
  },
  "& .slick-next": {
    background: "transparent",
    backgroundImage: `url(${nextButton})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    right: "-506px",
    zIndex: 1,
    height: "45px",
    width: "45px",
    top: -350,
    position: "relative",
    "&::before": {
      content: '""',
      position: "fixed",
      top: "-40px",
      left: "0",
      width: "30px",
      height: "30px",
    },
  }
});

const StyledCell = styled(TableCell)({
  border: '2px solid #E0E0E0',
  padding: '16px',
  textAlign: 'center',
  '&:first-of-type': {
    borderTopLeftRadius: '12px',
    borderBottomLeftRadius: '12px',
  },
  '&:last-of-type': {
    borderTopRightRadius: '12px',
    borderBottomRightRadius: '12px',
  },
});

const StyledCells = styled(TableCell)({
  padding: '16px',
  textAlign: 'center',
  borderBottom: "none"
});

const PaidCell = styled(StyledCell)({
  backgroundColor: '#D7F5E6', 
  borderTopLeftRadius: "8px",
  border: "none"
});

const Late30Cell = styled(StyledCell)({
  backgroundColor: '#F9EED3',
  border: "none"
});

const Late60Cell = styled(StyledCell)({
  backgroundColor: '#FBE2D3',
  border: "none"
});

const Late60PlusCell = styled(StyledCell)({
  backgroundColor: '#FBD9D8',
  border: "none",
  borderTopRightRadius: "8px"
});

const VacantCell = styled(StyledCell)({
  backgroundColor: '#fff',
  border: "none"
});

// Customizable Area End
