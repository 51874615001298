import React from "react";

import {
    Container,
    Box,
    Grid,
    Button,
    Paper,
    Typography,
    // Customizable Area Start
    StepLabel,
    Stepper,
    Step,
    // Customizable Area End
    Link,
} from "@mui/material";

// Customizable Area Start
import CustomEnroll1 from "../../../components/src/CustomEnroll1.web";
import CustomEnrol2 from "../../../components/src/CustomEnrol2.web";
import CustomEnroll3 from "../../../components/src/CustomEnroll3.web"

import { Home_h, bell_b, bookmark_b, dashboard_g, financial_f, key_k,
     myaccount, pie_p, workoreder, setting_s, key_logo, homeTwo } from "./assets";
import {
    createTheme,
    ThemeProvider,
    styled
} from "@mui/material/styles";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { activeMainDashboard, activeMainSetting, activeMainAccount, activeMainOrder, activeMainVendor, activeMainReminder, activePropertyMain, activeMainFinancial, activeMainReport, keyMainChain, activeKeyMain, settingsMain, accountsMain, dashboardLandLogo, financialMain, ordersMain, propertyMain, remindersMain, reportMain, vendorMain } from "../../dashboard/src/assets"
const getSteps = () => {
    return ['Property information', 'Contact information', 'Maintenance information'];
}
const theme = createTheme({
    palette: {
        primary: {
            main: "#6200ee",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const NavArea = styled(Box)({
    position: "fixed",
    top: 0,
    left: 0,
    height: "100%",
    overflowY: "auto",
    zIndex: 1000, 
    border: "1px solid rgb(208,203,199)",
    "@media(max-width: 992px)": {
      position: "unset",
      height: "auto",
      overflowY: "hidden",
      border: "none",
    }
  });
// Customizable Area End

import PortfolioNewController, {
    Props,
    configJSON,

} from "./PortfolioNewController";

export default class PortfolioNewpropertymanagement extends PortfolioNewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    getStepContent = (stepIndex: number) => {
        switch (stepIndex) {
            case 0:
                return (
                    <>
                    {
                        this.state.activeStep ===  0 && 
                          //@ts-ignore
                        <CustomEnroll1 
                      
                         handleChangePersonal={this.handleChange}
                         formErrors={this.state.formErrors}
                        formData={this.state.formData}
                        handleSelectChange={this.handleSelectChange}
                        handleAddTrade={this.handleAddTrade}
                        vendorTrade={this.state.vendorTrade}
                        handleKeyPress={this.handleKeyPress}
                        handleAddAmenities={this.handleAddAmenities}
                        anmitiesData={this.state.anmitiesData}
                        handleFilesChange={this.handleFilesChange}
                        formFilesInsurance={this.state.formFilesInsurance}
                        handleDelete={this.handleDelete}
                        handleRenewalDateChange={this.handleRenewalDateChange}
                        renewalDate={this.state.renewalDate}
                        handleManageStartChange={this.handleManageStartChange}
                        manageStartDate={this.state.manageStartDate}
                        handleRenewalChange={this.handleRenewalChange}
                        renewalDateData={this.state.renewalDateData}
                        />
                    }
                    </>
                )
            case 1:
                return (
                    <>
                    {
                        this.state.activeStep === 1 &&  
                         //@ts-ignore
                        <CustomEnrol2   
                         handleChangePersonal={this.handleChange}  
                         formData={this.state.formData}  
                         formErrors={this.state.formErrors} 
                         handleSelectChange={this.handleSelectChange} 
                         handleSelectChangetextArea={this.handleSelectChangetextArea}
                         handlePhotoFilesChange={this.handlePhotoFilesChange}
                         photoFile={this.state.photoFile}

                          />
                    }
                    </>
             )
            
            case 2:
                return (
                    <>
                    {
                       this.state.activeStep === 2 && 
                       //@ts-ignore
                        <CustomEnroll3   handleChangePersonal={this.handleChange}  formData={this.state.formData}   formErrors={this.state.formErrors} 
                               handleSelectChange={this.handleSelectChange} handleSelectChangetextArea={this.handleSelectChangetextArea}
                               insuranceDate={this.state.insuranceDate} 
                               handleInsDateChange={this.handleInsDateChange}
                               attachmentFile={this.state.attachmentFile}
                               handleattachmentChange={this.handleattachmentChange}
                               stateData={this.state}
                               whichOneData={this.state.whichOneData}
                               handleDelete={this.handleDelete}
                               handleCheck={this.handleCheck}
                        />
                    }
                    <Typography style={{ color:"green",display:"flex",alignItems:"center",justifyContent:"center"}}>{this.state.sucessMsg}</Typography>
                    </>
                )
            default:
                return null;
        }
    };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start


            <>

                <ThemeProvider theme={theme}>
                    <Grid container>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                            <NavArea>
                            <NavigationSidebar
                                data-test-id={"sidebarMenuIds"}
                                activeItem={this.state.activeMainItems}
                                sidebarItems={[
                                    { label: configJSON.dashboardLandloard1, icon: dashboardLandLogo, labelKey: configJSON.dashboardLandlabel, activeIcon: activeMainDashboard, pathName: configJSON.dashboardLandlabel },
                                    { label: configJSON.myAccountlandloard1, icon: accountsMain, labelKey: configJSON.settinglandlabel, pathName: configJSON.settinglandlabel, activeIcon: activeMainAccount },
                                    { label: configJSON.worklandloard1, icon: ordersMain, labelKey: configJSON.workOrderslandlabel,activeIcon: activeMainOrder, pathName: configJSON.workOrderslandlabel },

                                    { label: configJSON.preferredlandloard1, icon: vendorMain, labelKey: configJSON.prevendorlandlabel, activeIcon: activeMainVendor, pathName: configJSON.prevendorlandlabel },
                                    { label: configJSON.reminderslandloard1, icon: remindersMain, labelKey: configJSON.reminlandlabel, activeIcon: activeMainReminder, pathName: configJSON.reminlandlabel },
                                    { label: configJSON.myproplandloard1, icon: propertyMain, labelKey: configJSON.myproplandlabel1, activeIcon:activePropertyMain,  pathName: configJSON.myproplandlabel1, 
                                    },

                                    { label: configJSON.financialandloard1, icon: financialMain, labelKey: configJSON.financlandlabel, activeIcon: activeMainFinancial, pathName: configJSON.financlandlabel },
                                    { label: configJSON.reportlandloard1, icon: reportMain, labelKey: configJSON.reportlandlabel, activeIcon: activeMainReport, pathName: configJSON.reportlandlabel },
                                    { label: configJSON.chatLandloard1, icon: keyMainChain, labelKey: configJSON.chatlandlabel, activeIcon: activeKeyMain, pathName: configJSON.chatlandlabel },

                                    { label: configJSON.settingLandlord1, icon: settingsMain, labelKey: configJSON.settingLandlord1, activeIcon: activeMainSetting, pathName: configJSON.settingLandlord1 }
                                ]}
                                onClickSidebar={this.handleMainItemClicks}
                                  keasylogo={key_logo}
                                openDrawer={this.state.openLandlordDrawers}
                                onClickDrawer={this.handleLandlordDrawers}/>
                                </NavArea>
                        </Grid>
                        <Grid item md={10} xs={12} lg={10} style={{padding: "40px 0px 40px 0px"}} onClick={this.handleAddStatusData}>
                            <Grid container p={2} onClick={this.handleAddStatus}>
                                <Grid item xs={12} onClick={this.handleClearData}>
                                <Box data-testId="newprop" onClick={this.handleClickDel} style={{display: "flex", justifyContent: "space-between"}}>
                                   <Typography data-testId="clearButton" onClick={this.handleClear} sx={webStyle.newProperty}>{configJSON.newproperty}</Typography>
                                   <Button sx={{
                                        textTransform: 'none',
                                        width: "80px",
                                        height: "32px",
                                        fontWeight: 700,
                                        backgroundColor: "#FFC123",
                                        borderRadius: "8px",
                                        color: "#000000", 
                                        fontSize: "14px",
                                        padding: "0px",
                                        letterSpacing: 0,
                                        cursor: "pointer",
                                        fontFamily: "Outfit, sans-serif",
                                        "&:hover": {
                                            backgroundColor: "#FFD700", 
                                        },
                                        }} data-test-id="backBtnTest" onClick={this.navigateToAppointments}>Go Back</Button>
                                    </Box>


                                </Grid>
                            </Grid>
                        <Grid container spacing={7}>
                                <Grid item md={12} xs={12}style={{ marginTop: '20px' }}>
                                    <Stepper activeStep={this.state.activeStep} alternativeLabel connector={null}
                                        sx={{
                                            "& .css-109el20-MuiStepLabel-root.MuiStepLabel-alternativeLabel": {
                                                flexDirection: "revert"

                                            },
                                        }}
                                    >
                                        {getSteps().map((label, index) => (
                                            <Step key={index}
                                                sx={{
                                                    "& .css-1q0s5d2-MuiStepLabel-root.MuiStepLabel-alternativeLabel": {
                                                        flexDirection: "revert"
                                                    },
                                                    "& .MuiStepLabel-root .Mui-completed": {
                                                        color: "#34D399"

                                                    },
                                                    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                                                        color: "#000000",
                                                        fontWeight:700,
                                                        fontSize:"14px"

                                                    },
                                                    "& .MuiStepLabel-root .Mui-active": {
                                                        color: "#CC9200"
                                                    },
                                                    "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
                                                        color: "#000000",
                                                        fontWeight:700,
                                                        fontSize:"14px"

                                                    },
                                                    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                                                        fill: "#ffffff"
                                                    },
                                                    ".css-ea49we-MuiStep-root .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                                                        color: "#000000",
                                                        fontWeight:700,
                                                        fontSize:"14px"

                                                    },
                                                    "& .css-109el20-MuiStepLabel-root.MuiStepLabel-alternativeLabel": {


                                                    },
                                                    "& .css-1vyamtt-MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel": {
                                                        textAlign: "left"
                                                    },
                                                    "& .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                                                        marginTop: "3px",
                                                        marginLeft: "1rem"
                                                    }
                                                }}

                                            >
                                                <StepLabel
                                                    sx={{
                                                        "& .css-109el20-MuiStepLabel-root.MuiStepLabel-alternativeLabel": {
                                                            flexDirection: "revert",


                                                        },

                                                        color: "#000000",

                                                    }}
                                                >

                                                    <span style={webStyle.stepperstyle}>   {label}</span>

                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Grid>
                            </Grid>
                            <Container maxWidth="lg">
                                {this.getStepContent(this.state.activeStep)}

                            </Container>
                          
                            <Grid container spacing={4}>
                                <Grid item md={10} xs={12}>
                                 
                            <Box sx={webStyle.mediaQueary}>
                            <Box>
                              <Button
                                     data-test-id = "backbuttonId"
                                        variant="contained"
                                        style={{
                                            width: "184px",
                                            height: "44px",
                                            fontFamily: "outfit",
                                            fontSize: "16px",
                                            color: "#000000",
                                            fontWeight: 600,
                                            textAlign: "center",
                                            borderRadius: "10px",
                                            backgroundColor: "#FFF1CC",
                                            textTransform: "none",
                                            
                                            display: this.state.activeStep > 0 ? 'inline' : 'none'

                                        }}
                                        onClick={this.handleBackMain}
                                    >
                                     {configJSON.backbutton}
                                    </Button>

                                    <Button
                                    data-test-id = "nextbuttonId"
                                    data-testId="next-btn"
                                        variant="contained"
                                        style={{
                                            width: "184px",
                                            height: "44px",
                                            fontFamily: "outfit",
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            color: "#000000",
                                            textAlign: "center",
                                            backgroundColor: "#fab03d",
                                            borderRadius: "8px",
                                            marginLeft: '1em',
                                            textTransform: "none",
                                            cursor: "pointer",
                                          
                                          
                                          

                                        }}
                                        onClick={this.state.activeStep === 2
                                            ? this.handleSubmit
                                            : this.handleNextMain}
                                    >
                                       {this.state.activeStep === 2 ? "Submit" : "Next"}
                                    </Button>
                              </Box>
                            </Box>
                               
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                </ThemeProvider>




            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    stepperstyle: {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        fontfamily: "outfit"

    },
    newProperty: {
        fontSize: "24px",
        fontWeight: "700",
        lineHeight: "32px",
       

    },
    
    mediaQueary:{
        marginTop:'60px',
        textAlign:"right",
        "@media(max-width:600px)": {
          margin:0,
          textAlign:"center",
          marginBottom:"2rem"
          },

    },
    childWrapper: {
        paddingTop: '2rem',
    },
    inputStyle: {
        marginTop: 20,
        marginBottom: 20,
    },
    fileWrapper: {
        marginTop: 10,
        display: "flex",
        alignItems: "center",
    },
    fileName: {
        flex: 1,
    },
    submitBtnStyle: {
        marginTop: 20,
    },
    uploadBtnWrapper: {
        display: "flex",
        marginTop: 20,
    },
    uploadBtn: {
        padding: 7,
        paddingLeft: 15,
        paddingRight: 15,
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
        color: "#fff",
        borderRadius: 5,
        cursor: "pointer",
        marginRight: 15,
    },
    clearBtnStyle: {
        marginRight: 15,
    },
    bulkFileWrapper: {
        border: "1px solid #ccc",
        padding: 20,
        borderRadius: 5,
        marginBottom: 20,
    },
    bulkFileTopWrapper: {
        display: "flex",
        alignItems: "center",
    },
    textWrapper: {
        flex: 1,
    },
    fileItemWrapper: {
        display: "flex",
        border: "1px solid #ccc",
        padding: 10,
        marginTop: 15,
        borderRadius: 5,
        alignItems: "center",
    },
};
// Customizable Area End
