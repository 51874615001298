//@ts-nocheck
import React from "react";
// Customizable Area Start
import { 
   Box, 
   TextField,
   Typography,
   Button,
   Grid,
   InputBase,
   styled,
   ClickAwayListener,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
  } from "@mui/material";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { activeProfile,
         keasyIcon,
         profileMain,
         projects,
         activeProjects,
         hexagon,
         halfHexagon,
         download,
         invoice,
         key,
         approved,
         activekey } from "./assets";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import SearchIcon from "@mui/icons-material/Search";
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {Invoice} from './InvoiceBillingController.web';

// Customizable Area End

import InvoiceBillingController, {
  Props,
  configJSON,
} from "./InvoiceBillingController.web";

export default class InvoiceBilling extends InvoiceBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSortableTableCell = (property: string, label: string, unitIndex: number) => {
    return (
      <CustomTableCell key={property}>
        <Box display="flex" alignItems="center" justifyContent={"center"}>
          {label}
          <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
              data-test-id={`sortUpId,${unitIndex}`}
              onClick={() => this.handleSortRequest(property, "asc", unitIndex)}
            >
              <ArrowDropUp
              />
            </CustomIconButtonUp>
            <CustomIconButtonDown
              data-test-id={`sortDownId,${unitIndex}`}
              onClick={() => this.handleSortRequest(property, "desc", unitIndex)}
            >
              <ArrowDropDown
              />
            </CustomIconButtonDown>
          </Box>
        </Box>
      </CustomTableCell>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <InvoiceStyle>
           <NavigationSidebar
                    data-test-id={"vendorId"}
                    activeItem={this.state.activeVendorItem}
                    sidebarItems={[
                        { label: configJSON.vendorProfile, labelKey: configJSON.vendorLabelProfile, icon: profileMain, activeIcon: activeProfile, pathName: configJSON.vendorLabelProfile },
                        { label: configJSON.projects, labelKey: configJSON.vendorProjects, icon: projects, activeIcon: activeProjects, pathName: configJSON.vendorProjects },
                        { label: configJSON.invoiceLable, labelKey: configJSON.invoiceBlock, icon: invoice, activeIcon: invoice,pathName:configJSON.invoiceBlock },
                        { label: configJSON.ApprovedOwnersLable, labelKey: configJSON.ApprovedOwnersBlock, icon: approved, activeIcon: approved,pathName:configJSON.ApprovedOwnersBlock },
                        { label: configJSON.chatLable, labelKey: configJSON.chatBlock, icon: key, activeIcon: activekey,pathName:configJSON.chatBlock }

                    ]}
                    onClickSidebar={this.handleVendoritemClick}
                    keasylogo={keasyIcon}
                    openDrawer={this.state.openVendorDrawer}
                    onClickDrawer={this.handleVendorDrawer}
           />
           <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}
          sx={{
            padding: {
              flexGrow: 1,
              borderLeft: "1px solid rgb(208,203,199)",
            },
          }}>
           <MainWorkContainer>
            <WorkStyles>
              <WorkOrderHead data-test-id="seeTextId">
                 Sent Invoices
              </WorkOrderHead>
              <Grid item spacing={2}>
                <Grid item xs={12} md={6} justifyContent="flex-start">
                <ClickAwayListener  data-test-id="click-away"  onClickAway={() => this.setState({ startDateOpen: false,endDateOpen:false })}  mouseEvent="onMouseDown">
                  <Box className="search-main-content">
                      <Box className="left">
                        <Box sx={{display:"flex",gap:"1rem"}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ marginBottom: 2,
                             }}>
                              <DesktopDatePicker
                                  data-test-id="desktopDatePicker"
                                  value={this.state.startDate}
                                  onClose={() => this.setState({ startDateOpen: false })}
                                  onChange={(date) => this.handleDateChange(date, 'startDate')}
                                  open={this.state.startDateOpen} 
                                  renderInput={(params) =>
                                    <TextField
                                    data-test-id="startDateDatePicker"
                                    onClick={()=>{this.handleOpen("startDate")}}
                                    {...params}
                                    label="Start date"
                                    InputProps={{
                                      ...params.InputProps,
                                      style: {
                                        fontFamily: 'Outfit, sans-serif', 
                                        lineHeight: '18px', 
                                        fontWeight: 400, 
                                        borderRadius: '8px',
                                        width: '100%', 
                                        padding: '0rem 1rem', 
                                        fontSize: '16px', 
                                      },
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        lineHeight: '19px', 
                                        color: '#A3978F',
                                        fontSize: '16px',
                                        fontWeight: 400, 
                                        fontFamily: 'Outfit', 
                                      },
                                    }}
                                    sx={{
                                      '& .MuiInputBase-root': {
                                        paddingRight: '8px',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        color: '#BAB1AB',
                                      },
                                    }}
                                  />
                                    }
                                />
                            </Box>
                          </LocalizationProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ marginBottom: 2,
                              }}>
                              <DesktopDatePicker
                                  data-test-id="endDesktopDatePicker"
                                  value={this.state.endDate}
                                  onChange={(date) => this.handleDateChange(date, 'endDate')}
                                  onClose={() => this.setState({ endDateOpen: false })}
                                  open={this.state.endDateOpen}
                                  renderInput={(params) =>
                                    <TextField
                                    data-test-id="endDesktopDatePickerField"
                                    {...params}
                                    InputLabelProps={{
                                      style: {
                                        fontFamily: 'Outfit', 
                                        fontSize: '16px',
                                        fontWeight: 400, 
                                        lineHeight: '19px', 
                                        color: '#A3978F',
                                      },
                                    }}
                                    onClick={()=>{this.handleOpen("endDate")}}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: {
                                        width: '100%', 
                                        borderRadius: '8px',
                                        padding: '0rem 1rem', 
                                        fontFamily: 'Outfit, sans-serif', 
                                        fontSize: '16px', 
                                        fontWeight: 400, 
                                        lineHeight: '18px', 
                                      },
                                    }}
                                  
                                    sx={{
                                      '& .MuiInputBase-root': {
                                        paddingRight: '8px',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        color: '#BAB1AB',
                                      },
                                    }}
                                    label="End date"

                                     />
                                    }
                                />
                            </Box>
                          </LocalizationProvider>
                        </Box>
                            <Button
                              data-test-id="apply-btn"
                              variant="contained"
                              style={{
                                  width:"10rem",
                                  height:"3.7rem",
                                  fontFamily: "outfit",
                                  fontSize: "16px",
                                  fontWeight: 700,
                                  color: "#000000",
                                  textAlign: "center",
                                  backgroundColor: "#FFC123",
                                  borderRadius: "8px",
                                  textTransform: "none",
                                  cursor: "pointer"}}
                                  onClick={this.handleFilter}
                            >
                            Apply  
                          </Button>
                      </Box>
                  </Box>
                </ClickAwayListener>
               
                </Grid>
                 <BorderContainer key={1}>
                    <Box
                        sx={webStyles.searchRow}
                      >
                        <SearchIcon sx={{ color: "#BAB1AB" }} />
                        <InputBase
                          name="searchInput"
                          id="searchInput"
                          data-test-id="search_block"
                          placeholder="Search"
                          inputProps={{ "aria-label": "search" }}
                          size="small"
                          sx={{
                            width: "100%",
                            marginLeft: "5px",
                            marginTop: "3px",
                          }}
                          value={this.state.searchInput}
                          onChange={this.handleInputChange}
                        />
                    </Box>
                    <TableContainer style={{ marginLeft: "1rem" }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {this.renderSortableTableCell("Invoice ID", "Invoice ID", 1)}
                            {this.renderSortableTableCell("Property", "Property", 3)}
                            {this.renderSortableTableCell("Category", "Category", 5)}
                            {this.renderSortableTableCell("Work needed", "Work needed", 6)}
                            {this.renderSortableTableCell("Invoice date", "Invoice date", 4)}
                            {this.renderSortableTableCell("Due date", "Due date", 9)}
                            {this.renderSortableTableCell("Price", "Price", 8)}
                            {this.renderSortableTableCell("Taxes", "Taxes", 6)}
                            {this.renderSortableTableCell("Transaction status", "Transaction status", 6)}
                            <TableCell 
                                  style={{
                                    color: "#A3978F",
                                    textAlign: "center",
                                    fontSize: "12px",
                                    fontWeight: 700,
                                    padding: "0px 10px 0px 10px !important",
                                    whiteSpace: "nowrap",
                                    alignItems:"center"
                                  }}
                                >
                                  Receipts
                          </TableCell>

                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody data-test-id="table-body" >
                      {this.state.invoices.map((invoice, invoiceIndex) => {
                        const { invoice_detail } = invoice.attributes;
                        const { work_order } = invoice_detail;
                        return (
                          <TableRow key={invoice.id}>
                            <CustomDetailTableCell>{invoice.id}</CustomDetailTableCell>
                            <CustomDetailTableCell>{invoice_detail.property_name}</CustomDetailTableCell>
                            <CustomDetailTableCell>{work_order?.description}</CustomDetailTableCell>
                            <CustomDetailTableCell>{work_order?.work_needed}</CustomDetailTableCell>
                            <CustomDetailTableCell>{invoice_detail.invoice_date}</CustomDetailTableCell>
                            <CustomDetailTableCell>{invoice_detail.due_date}</CustomDetailTableCell>
                            <TaxTableCell isTax={false}>
                            {`$${Number(invoice_detail.price) % 1 === 0 ? Number(invoice_detail.price).toFixed(0) : Number(invoice_detail.price).toFixed(2)}`}
                          </TaxTableCell>

                          <TaxTableCell isTax={true}>
                            {`$${Number(invoice_detail.taxes) % 1 === 0 ? Number(invoice_detail.taxes).toFixed(0) : Number(invoice_detail.taxes).toFixed(2)}`}
                          </TaxTableCell>
                            <TableCell sx={{display:"flex",justifyContent:"center"}}>
                                      <StatusTableCell status={"Completed"}> Completed</StatusTableCell>
                                      </TableCell>
                            <TableCell align="center">
                            <ScheduledNewWaiting data-test-id="download" sx={{display:"flex",justifyContent:"center"}} onClick={()=>this.handleDownload(invoice.attributes.invoice_pdf_url)}>
                                <img className="image-icon" src={download} alt="bill" />
                                <ScheduledDate>Download</ScheduledDate>
                              </ScheduledNewWaiting>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  
                      </Table>
                    </TableContainer>
                </BorderContainer>
              </Grid>
          
            </WorkStyles>
          </MainWorkContainer>
          <Box className="bgImgWrapper" data-test-id="bgImgId">
              <Box className="backgroundImage">
                <img src={hexagon} alt="hexagon-one" className="hexImg" />
              </Box>
              <Box className="backgroundImage">
                <img src={hexagon} alt="hexagon-two" className="hexImg" />
              </Box>
              <Box className="backgroundImage">
                <img src={hexagon} alt="hexagon-three" className="hexImg" />
              </Box>
              <Box className="backgroundImage">
                <img src={halfHexagon} alt="half-hexagon" className="hexImg" />
              </Box>
          </Box>
        </Box>
      </InvoiceStyle>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const InvoiceStyle = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "& .bgImgWrapper": {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "2rem",
    "@media (max-width: 992px)": {
      display: "flex",
      justifyContent: "end", 
      width: "100%",
      marginBottom: "0rem", 
      marginTop:"6rem",
      overflow: "hidden",
    },
  },
  "& .backgroundImage": {
    width: "250px",
    "@media (max-width: 1220px)": {
      width: "180px",
    },
    "@media (max-width: 992px)": {
      width: "120px", 
    },
  },
  "& .hexImg": {
    objectFit: "cover",
  },
});

const MainWorkContainer = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px",
  },
});

const StatusTableCell = styled(TableCell)<{ status: string }>(({ status }) => ({
  fontFamily: "Outfit",
  fontWeight: 700,
  padding: "0.5rem",
  color: status === "Completed" ? "#059669" : "#FFA500",
  backgroundColor: status === "Completed" ? "#D1FAE5" : "#FFF3E0",
  borderRadius: "40px",
  textAlign: "center",
  textTransform: "capitalize",
  "@media(max-width: 768px)": {
    fontSize: "10px",
    padding: "0.2rem",
  },
}));

const TaxTableCell = styled(TableCell)<{ isTax: boolean }>(({ isTax }) => ({
  fontFamily: "Outfit",
  fontWeight: 700,
  padding: "0.5rem",
  color: isTax ? "#DC2626" : "#059669",
  textAlign: "center",
  "@media(max-width: 768px)": {
    fontSize: "10px",
    padding: "0.2rem",
  },
}));

const WorkStyles = styled(Box)({
  width: "90%",
  marginTop: "42px",
  "& .search-main-content": {
    display: "flex",
    "@media(max-width: 600px)": {
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  "& .left": {
    display: "flex",
    gap: "1rem",
    "@media(max-width: 600px)": {
      flexDirection: "column",
      width: "100%",
    },
  },
  "& .right": {
    display: "flex",
  },
  "& .search-contents": {
    border: "1px solid #CBD5E1",
    borderRadius: "50px",
    height: "44px",
    display: "flex",
    paddingLeft: "15px",
    alignItems: "center",
    gap: "13px",
    "@media(max-width: 600px)": {
      borderRadius: "8px",
      height: "36px",
    },
  },
  "& .search-icons": {
    height: "24px",
    width: "24px",
  },
  "& .search-text": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#A3978F",
    "@media(max-width: 420px)": {
      fontSize: "12px",
    },
  },
  "& .search-inputs": {
    width: "100%",
  },
  "& .properties-dropdown": {
    width: "142px",
    height: "44px",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    borderRadius: "8px",
    padding: "2px 10px",
    justifyContent: "space-around",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000",
    },
    "@media(max-width: 420px)": {
      fontSize: "12px",
      width: "100px",
      height: "36px",
    },
  },
  "& .view-completed": {
    width: "180px",
    backgroundColor: "rgb(253,242,208)",
    height: "44px",
    display: "flex",
    alignItems: "center",
    padding: "2px 10px",
    borderRadius: "8px",
    justifyContent: "space-around",
    cursor: "pointer",
    "@media(max-width: 460px)": {
      fontSize: "12px",
      height: "35px",
      width: "100%",
    },
    "@media(max-width: 347px)": {
      padding: "2px 5px",
    },
  },
  "& .icon": {
    mixBlendMode: "multiply",
  },
  "& .image-icon": {
    width: "16px",
    height: "16px",
  },
  "& .text-data": {
    fontSize: "16px",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px",
    },
  },
  "& .new-data": {
    fontSize: "18px",
    color: "#CC9200",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px",
    },
  },
  "& .new-property": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    borderRadius: "8px",
    justifyContent: "space-around",
    paddingLeft: "5px",
    paddingRight: "5px",
    cursor: "pointer",
  },
  "& .filter-content": {
    display: "flex",
    alignItems: "center",
    paddingRight: "20px",
  },
  "& .filter": {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    "@media(max-width: 600px)": {
      flexDirection: "column",
    },
  },
  "& .filter-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000",
  },
  "& .clear-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#CC9200",
    cursor: "pointer",
  },
  "& .isUnitsDisabled": {
    display: "none",
  },
});

const BorderContainer = styled(Box)({
  border: "1px solid #FFD466",
  borderRadius: "8px 8px 32px 8px",
  padding: "1rem",
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  "& .scrollable-container": {
    height: "300px",
    overflowY: "auto",
  },
  "& .isUnitsDisabled": {
    display: "none",
  },
  "@media(max-width: 600px)": {
    width: "100%",
    padding: "0.5rem",
    "& .scrollable-container": {
      height: "200px",
    },
  },
});

const WorkOrderHead = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  color: "#000000",
  marginBottom: "20px",
  "@media(max-width: 768px)": {
    fontSize: "20px",
    marginBottom: "10px",
  },
});

const CustomIconButtonUp = styled(Typography)({
  marginBottom: "-14px",
  cursor: "pointer",
  "@media(max-width: 768px)": {
    fontSize: "12px",
  },
});

const CustomIconButtonDown = styled(Typography)({
  marginTop: "0px",
  cursor: "pointer",
  "@media(max-width: 768px)": {
    fontSize: "12px",
  },
});

const CustomTableCell = styled(TableCell)({
  fontFamily: "Outfit",
  color: "#A3978F",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 700,
  padding: "0px 10px 0px 10px !important",
  whiteSpace: "nowrap",
  "@media(max-width: 768px)": {
    fontSize: "10px",
    padding: "0px 5px 0px 5px !important",
  },
});

const ScheduledNewWaiting = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  cursor: "pointer",
});

const ScheduledDate = styled(Typography)({
  color: "#CC9200",
  fontFamily: "Outfit",
  fontSize: "14px",
  fontWeight: 700,
  "@media(max-width: 768px)": {
    fontSize: "12px",
  },
});

const CustomDetailTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: "12px",
  whiteSpace: "nowrap",
  color: "#A3978F",
  fontWeight: 400,
  textAlign: "center",
  borderBottom: "1px solid #e0e0e0",
  "@media(max-width: 768px)": {
    fontSize: "10px",
  },
}));

const webStyles = {
  searchRow: {
    border: "1px solid #BAB1AB",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    padding: "5px",
    width: "60%",
    marginTop: "1rem",
    marginLeft: "1rem",
    "@media(max-width: 600px)": {
      width: "50%",
      padding: "5px 2px",
      borderRadius: "8px",
    },
  },
};
// Customizable Area End