// Customizable Area Start
import React from "react";
import Accordion from '@mui/material/Accordion';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from '@mui/material/Modal';
import { KeyboardArrowUp, KeyboardArrowDown, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Typography,
  InputBase,
  styled,
  Button,
  Menu,
  IconButton,
  ListItemText,
  Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, DialogTitle, Pagination,TextField, MenuItem
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {HomeImg} from "../../visualanalytics/src/assets";

import {
  createTheme,
  ThemeProvider,


} from "@mui/material/styles";
import {
  searchSearch, downDown, plusPlus, filterFilter, keylogoLogo,
  homemyruch,
  lockLock,
  dialogDialogimage, closeImage, pencil, imageIcon, imageOne, imageTwo, imageThree, plusIcon, downArrow
} from "./assets";
import {hexagon,halfHexagon,searchIcon,filterIcon,findKeys} from '../../appointmentmanagement/src/assets';
import { activeMainDashboard, activeMainSetting, activeMainAccount, activeMainOrder, activeMainVendor, activeMainReminder, activePropertyMain, activeMainFinancial, activeMainReport, keyMainChain, activeKeyMain, settingsMain, accountsMain, dashboardLandLogo, financialMain, ordersMain, propertyMain, remindersMain, reportMain, vendorMain } from "../../dashboard/src/assets"
import PortfolioManagementController, {
  Props,
  configJSON,
  PropertyLabel,
  PropertyData,
  Unit,
  Data
} from "./PortfolioManagementController";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
interface CustomChipProps {
  label: string;
  id: string
}

const PortfoliyoStyle = styled(Box)({

  "& .UpperAddunitStyle": {
    fontSize: "16px",
  fontWeight: 700,
  lineHeight: "18px",
  fontfamily: "outfit",
  color: "#000000",
  "@media(max-width:600px)": {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
    fontfamily: "outfit",
    color: "#000000",
  },
  },
  "& .UpperAddunitStyletwo": {
    fontSize: "16px",
  fontWeight: 400,
  lineHeight: "18px",
  fontfamily: "outfit",
  color: "#000000",
  "@media(max-width:600px)": {
    fontSize: "11px",
    fontWeight: 400,
    lineHeight: "18px",
    fontfamily: "outfit",
    color: "#000000",

  },
  },
  "& .heading-text": {
    fontSize: "24px",
    fontWeight: 700,
    color: "#000000",
    marginTop: "42px",
  },
  "& .main-content": {
    display: "flex"
  },
  "& .search-main-content": {

    display: "flex",
    gap: '1rem',
    "@media(max-width:600px)": {
      width: "100%",
      height: "100%",
      display: "flex",
      position: "relative",
      flexDirection: "row",

    },
  },
  "& .search-content": {

    height: "44px",
    border: "1px solid #CBD5E1",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "15px",
    gap: "13px",
  },
  "& .search-icon": {
    width: "24px",
    height: "24px",
  },
  "& .search-text": {
    fontSize: "16px",
    fontWeight: 400,
    color: "#A3978F"
  },
  "& .search-input": {
    width: "800px"
  },
  "& .properties-dropdown": {
    backgroundColor: "#FFF1CC",
    width: "142px",
    height: "44px",
    display: "flex",
    alignItems: "center",
    marginLeft: "15px",
    borderRadius: "8px",
    justifyContent: "space-around",
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .text-data": {
    fontSize: "16px",
    fontWeight: 700,
    fontfamily: "outfit",
  },
  "& .new-property": {
    backgroundColor: "#FFC123",
    width: "166px",
    height: "44px",
    display: "flex",
    alignItems: "center",

    borderRadius: "8px",
    justifyContent: "space-evenly",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  "& .filter-content": {

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "15px"
  },
  "& .filter-contenttwo": {

    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap:'1rem'
    
  },
  "& .filter": {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  "& .filter-text": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000"
  },
  "& .clear-text": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#CC9200",
    cursor: "pointer"
  },
  "& .unit-data": {
    border: "1px solid #FFD466",
    borderRadius: "8px",
    marginTop: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

  },
  "& .unit-text": {
    display: "flex",
    alignItems: "center",
    justifyContent: " flex-start",
    gap: "14px",
    padding: "25px"
  },
  "& .chip-content": {
    width: "88px",
    height: "22px",
    backgroundColor: "#D1FAE5",
    color: "#059669",
    fontSize: "12px",
    fontWeight: 700
  },
  "& .info-text": {
    fontSize: "14px",
    fontWeight: 700,
    color: "#CC9200"
  },
  "& .unit-content": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
    marginRight: "75px"
  },
  "& .unit-sub-content": {
    display: "flex",
    gap: "4px",
    alignItems: "center",
    justifyContent: "center"
  },
  "& .home-icon": {
    width: "19px",
    height: "19px",
  },
  "& .addunit-content": {
    display: "flex",
    justifyContent: "end",
    "@media(max-width:600px)": {
      display: "flex",
      justifyContent: "left",
      marginTop: "1rem"
    },



  },
  "& .button-content": {
    width: "120px",
    height: "32px",
    backgroundColor: "#FFF1CC",
    gap: "6px",
    color: "#000000",
    fontSize: "16px",
    fontWeight: 700,
    display: "flex",
    alignItems: "center !important",
    justifyContent: "center !important",
    borderRadius:"8px"
  },
  "& .add-icon": {
    width: "20px",
    mixBlendMode: "multiply"
  },
  "& .slick-next::before": {
    color: "#FFC123",
    lineHeight: "0",
    fontSize: "58px",
    "@media(max-width:600px)": {
      fontSize: "43px",
    }
  },
  "& .slick-prev::before": {
    color: "#FFC123",
    lineHeight: "0",
    fontSize: "58px",
    "@media(max-width:600px)": {
      fontSize: "43px",
    }
  },
  "& .slick-prev": {
    left: "-74px",
    "@media(max-width:600px)": {
      left: "-47px",
    }
  },
  "& .slick-next": {
    right: "30px",
    "@media(max-width:600px)": {
      right: "8px",
    }
  },
  "& .slick-list": {
    width: "487px",
    "@media(max-width:600px)": {
      width: "150px",
    }
  },
  "& .sliderContainer": {
    height: "147px",
    "@media(max-width:600px)": {
      marginTop: "16px",
      marginLeft: "28px",
    }
  },
  "& .add-unit": {
    backgroundColor: "#FFC123",
    width: "74px",
    height: "35px",
    display: "flex",
    alignItems: "center",

    borderRadius: "8px",
    justifyContent: "space-around",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "17px",
    marginLeft: "78%",
  },
  "& .unit-text-data": {
    fontSize:"16px",
    fontWeight: 700,
    color:"#000000"
  }
});

const NavArea = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});


const Boxnone = styled(Box)({
  display: "block", marginRight:"28px", marginLeft:"28px",

  "@media(max-width:600px)": {
    display: "none"


  },


})
const Boxparentdiv = styled(Box)({
  display: "flex", justifyContent: "space-between", alignItems: 'center', paddingLeft: "4rem", paddingRight: "4rem", marginTop: "2rem",

  "@media(max-width:600px)": {
    display: "none"


  },


})
const Boxgivemobile = styled(Box)({

  border: '1px solid #FFD466', borderRadius: "8px", padding: "1rem", marginTop: "3rem",

  "@media(max-width:600px)": {
    borderRadius: "8px 8px 20px",
    marginTop: "2rem"

  },
  "& .buttoms-content": {
    display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "2rem",
    "@media(max-width:600px)": {
      marginTop: "1rem"
    },
  }
});
const Boxaddtenents = styled(Box)({

  display: "flex", gap: '0.5rem', justifyContent: "center", alignItems: 'center',

  "@media(max-width:600px)": {
    display: "none"


  },
});
const Boxaddtenent = styled(Box)({
  display: "none",
  marginTop: "0.8rem",


  "@media(max-width:600px)": {
    display: "flex", gap: '0.5rem', justifyContent: "left", alignItems: 'left',


  },
});
const Divcontainer = styled(Box)({
  display: "flex", justifyContent: "space-between",


  "@media(max-width:600px)": {
    flexDirection: "column",

    textAlign: "left"


  },
});
const Gapless = styled(Box)({
  display: "flex", justifyContent: "space-between", alignItems: "center", gap: "2rem",


});
const Boxfordesknones = styled(Box)({
  display: "none",


  "@media(max-width:600px)": {
    display: 'block'


  },
});

const Boxfordesknone = styled(Box)({
  display: "flex", justifyContent: "space-between", width: "100%", marginTop: "1rem",
  "& .text-data": {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "10px",
    fontfamily: "outfit",
    color: '#A3978F'
  }
});

const Boxbottmfilter = styled(Box)({
  display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: '1rem',
  "@media(max-width:600px)": {
    flexDirection: "column",
    textAlign: "left",
    display: "table-row-group",
    marginTop: "1rem"


  },


})

const Boxfilterchild = styled(Box)({

  display: "flex", justifyContent: "flex-start", alignItems: "center", gap: '1rem',
  "@media(max-width:600px)": {

    marginTop: "1rem"


  },

})
const BoxEditStyle = styled(Box)({

  display: "flex", justifyContent: "space-between",
  "@media(max-width:600px)": {

   flexDirection:'column'


  },

})
const BoxChipformobile = styled(Box)({
display:"none",
 
  "@media(max-width:600px)": {
display:"block",
marginTop:"1rem"



  },

})
const BoxChipfordesk = styled(Box)({
display:"block",
 
  "@media(max-width:600px)": {
display:"none"


  },

})
const BoxImage = styled(Box)({

 
  "@media(max-width:600px)": {
mmarginTop:"0.6rem"


  },

})
const CustomIconButtonUp = styled(Typography)({
  marginBottom: "-14px",
  cursor: "pointer"
});

const CustomIconButtonDown = styled(Typography)({
  marginTop: "0px",
  cursor: "pointer"
});

const StyledTextFieldthree = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    color: '#000000',
  },
  '& .MuiInputLabel-root': {
    color: '#BAB1AB',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#BAB1AB',
    },
    '&:hover fieldset': {
      borderColor: '#BAB1AB',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#BAB1AB',
    },
    '&.MuiOutlinedInput-root':{
      height: "44px",
      marginTop: "7px"
    },
    '&.MuiOutlinedInput-root.MuiOutlinedInput-root': {
      height: "40px"
    }
  },
}));

const maxDatas = ["1", "2", "3", "4", "5", "6","7", "8", "9", "10"]

export default class PortfolioManagement extends PortfolioManagementController {
  constructor(props: Props) {
    super(props);
  }

  handleValue = (value: boolean) => {
    if(value === true){
      return "Yes"
    }
    return "No"
  }

  CustomChips = ({ label, id }: CustomChipProps) => {
    return (
      <Box style={webStyle.customChips} data-test-id="delete-btn" >
        <IconButton size="small" sx={{ padding: "0" }}>
          < CloseIcon onClick={() => this.handleDelete(id)} />
        </IconButton>
        <Typography sx={{ marginTop: "3px" }}>{label}</Typography>
      </Box>
    );
  };

  handleName = (name: string) => {
    if(name?.length > 15){
      return name.substring(0, 15) + '\n' + name.substring(15)
    }
    return name
  }
  renderSortableTableCell = (property: string, label: string, unitIndex: number) => {
    return (
      <CustomTableCell key={property}>
        <Box display="flex" alignItems="center" justifyContent={"center"}>
          {label}
          <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
              data-test-id={`sortUpId,${unitIndex}`}
              onClick={() => this.handleSortRequest(property, "asc", unitIndex)}
            >
              <ArrowDropUp
              />
            </CustomIconButtonUp>
            <CustomIconButtonDown
              data-test-id={`sortDownId,${unitIndex}`}
              onClick={() => this.handleSortRequest(property, "desc", unitIndex)}
            >
              <ArrowDropDown
              />
            </CustomIconButtonDown>
          </Box>
        </Box>
      </CustomTableCell>
    );
  };

  renderFilter = () => {
    return (
      <FilterContainer>
        {this.state.chips.map((chip, chipIndex) => (
          <FilterChips key={chipIndex}>
            <FilterClose data-test-id = {`removedChipId,${chipIndex}`} onClick={() => this.removeChip(chipIndex)} />
            <FilterDetails>
              {chip.propertyName} | {chip.unitName}
            </FilterDetails>
          </FilterChips>
        ))
        }
      </FilterContainer>
    )
  }

  render() {
    const { currentPage } = this.state;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <WorkOrderStyle>
            <NavArea>
            <NavigationSidebar
              data-test-id={"sidebarMenuIds"}
              activeItem={this.state.activeMyproperty}
              sidebarItems={[
                { label: configJSON.dashboardLandloard1, icon: dashboardLandLogo, labelKey: configJSON.dashboardLandlabel, activeIcon: activeMainDashboard, pathName: configJSON.dashboardLandlabel },
                { label: configJSON.myAccountlandloard1, icon: accountsMain, labelKey: configJSON.settinglandlabel,  activeIcon: activeMainAccount, pathName: configJSON.settinglandlabel },
                { label: configJSON.worklandloard1, icon: ordersMain, labelKey: configJSON.workOrderslandlabel, activeIcon: activeMainOrder, pathName: configJSON.workOrderslandlabel },

                { label: configJSON.preferredlandloard1, icon: vendorMain, labelKey: configJSON.prevendorlandlabel, activeIcon: activeMainVendor, pathName: configJSON.prevendorlandlabel },
                { label: configJSON.reminderslandloard1, icon: remindersMain, labelKey: configJSON.reminlandlabel, pathName: configJSON.reminlandlabel, activeIcon: activeMainReminder },
                {
                  label: configJSON.myproplandloard1, icon: propertyMain, labelKey: configJSON.myproplandlabel1, activeIcon: activePropertyMain, pathName: configJSON.myproplandlabel1,
                },
                { label: configJSON.financialandloard1, icon: financialMain, labelKey: configJSON.financlandlabel, activeIcon: activeMainFinancial, pathName: configJSON.financlandlabel },
                { label: configJSON.reportlandloard1, icon: reportMain, labelKey: configJSON.reportlandlabel, pathName: configJSON.reportlandlabel, activeIcon: activeMainReport },
                { label: configJSON.chatLandloard1, icon: keyMainChain, labelKey: configJSON.chatlandlabel, pathName: configJSON.chatlandlabel, activeIcon: activeKeyMain },

                { label: configJSON.settingLandlord1, icon: settingsMain, labelKey: configJSON.settingLandlord1, pathName: configJSON.settingLandlord1,  activeIcon: activeMainSetting }
              ]}
              onClickSidebar={this.handleMainItemMyproClicks}
              keasylogo={keylogoLogo}
              openDrawer={this.state.openLandlordDrawermypro}
              onClickDrawer={this.handleLandlordMyproDrawers} />
              </NavArea>
          <WorkArea display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}
          >
            <MainWorkContainer>
              <WorkStyles>
                <WorkOrderHead data-test-id="seeTextId">
                  {configJSON.myproplandloard1}
                </WorkOrderHead>
                <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Box className="search-contents">
                    <img
                      className="search-icons"
                      src={searchIcon} alt="location" />
                    <InputBase
                      data-test-id = "searchId"
                      className="search-inputs"
                      placeholder="Search"
                      inputProps={{ "aria-label": "search" }}
                      value={this.state.searchBar}
                      onChange={(searchEvent) => this.handleSearchBar(searchEvent)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} justifyContent="flex-start">
                  <Box className="search-main-content">
                    <Button data-test-id="propertyClickId" className="properties-dropdown" onClick={this.handleProperClick}>
                      <Typography data-test-id="propertyNameId" className="text-data">{configJSON.propertiesHead}</Typography>
                      <KeyboardArrowDownIcon />
                    </Button>
                    <Box data-test-id="new-propertyBtn" className="new-property" onClick={() => this.navigateTo({ props: this.props, screenName: "PortfolioNewpropertymanagement" })}>
                      <img
                        className="icon"
                        src={plusPlus} alt="plus" />
                      <Typography className="text-data" >New property</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box className="filter-content">
                    <Box className="filter">
                      <img
                        className="icon"
                        src={filterIcon} alt="filter" />
                      <Typography className="filter-data" data-test-id="headClickId">Filters</Typography>
                    </Box>
                    <FilterWeb>
                      {this.renderFilter()}
                    </FilterWeb>
                    <Typography className="clear-data" data-test-id="clearOrderId" onClick={this.clearChips}>Clear all</Typography>
                  </Box>
                </Grid>
                <FilterMobile>
                  {this.renderFilter()}
                </FilterMobile>
              </Grid>
              <Box className={this.handleProperty(this.state.properties)}>
                {this.state.properties.map((propertyItems: any, proIndex: number) => (
                  <React.Fragment key={proIndex}>
                    <Grid container spacing={2} mt={2}>
                      <GridItem item xl={6} lg={6} md={6} sm={12} display={"flex"} alignItems={"center"}>
                        <HeadItems>
                          <HeadName>{propertyItems.attributes.property_name}</HeadName>
                          <MainHeadings>{propertyItems.attributes.address}</MainHeadings>
                        </HeadItems>
                      </GridItem>
                      <GridItem item xl={6} lg={6} md={6} sm={12}>
                        <CompletedDetails>
                        <Box className="addunit-content">
                          <Box data-test-id="add-unit-btn" className="button-content" onClick={() => this.handleClickUnit(propertyItems.id)}>
                            <img
                              className="icon"
                              src={plusPlus} alt="plus" />
                            <Typography className="unit-text-data">Add unit</Typography>
                          </Box>
                        </Box>
                        </CompletedDetails>
                      </GridItem>
                    </Grid>
                    {propertyItems.attributes.units.map((unitDetails: any, unitIndex: number) => (
                      <BorderContainer key={unitIndex}>
                        <Box style={{display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "20px"}}>
                          <OrderUnitContainer>
                            <OrderUnitName>{unitDetails.unit_name}</OrderUnitName>
                            <OrdersProgress>{unitDetails.status}</OrdersProgress>
                          </OrderUnitContainer>
                          <CustomGrid item xl={6} lg={4} md={4} sm={12} xs={12}>
                          <Box data-test-id = "gotoPropertyId" display={"flex"} gap={1} onClick={() => this.handleClickOpen(propertyItems.id)}>
                            <img src={HomeImg} alt="home" style={{width: "14px", height:"14px"}} />
                            <FindKeyName>Property information</FindKeyName>
                          </Box>
                          <Box data-test-id = "findKeysId" display={"flex"} marginLeft={"20px"} marginRight={"20px"} gap={1} onClick={() => this.handleClickOpendialog(propertyItems.id)}>
                            <img src={findKeys} alt="findKey" className="image-icon" />
                            <FindKeyName>Find keys</FindKeyName>
                          </Box>
                          <Box data-test-id = "addReminderId" display={"flex"} gap={1} onClick={() => this.navigateTo({ props: this.props, screenName: "NewTenant", id: unitDetails.id })}>
                            <AddCircleOutlineOutlinedIcon style={{ color: '#CC9200' }} className="image-icon"/>
                            <FindKeyName>Add tenant</FindKeyName>
                          </Box>
                        </CustomGrid>
                        </Box>
                        <TableContainer sx={{ maxHeight: 300, zIndex: 1 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {this.renderSortableTableCell("id", "Status" , unitIndex)}
                                {this.renderSortableTableCell("category", "Tenant name", unitIndex)}
                                {this.renderSortableTableCell("category", "Date of birth", unitIndex)}
                                <CustomTableCell>Email</CustomTableCell>
                                <CustomTableCell>Phone</CustomTableCell>
                                {this.renderSortableTableCell("cost", "Lease Start", unitIndex)}
                                {this.renderSortableTableCell("status", "Lease End", unitIndex)}
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.sortData(unitDetails.tenants, unitIndex).map((rowOrder: any, workIndex: number) => {
                                return (
                                  <React.Fragment key={`workOrder-${rowOrder.tenant.id}`}>
                                    <TableRow className={this.handleDetailsContainer(unitDetails.landlord_work_orders)}>
                                      <TableCell>
                                        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                          <OrderStatus data-test-id="statusId" sx={this.priorityStyleHandler(rowOrder.status)}>
                                            {rowOrder.tenant.status}
                                          </OrderStatus>
                                        </Box>
                                      </TableCell>
                                      <CustomDetailTableCellId data-test-id="rowId">{rowOrder.tenant.name}</CustomDetailTableCellId>
                                      <CustomDetailTableCell>{this.formatDate(rowOrder.tenant.date_of_birth)}</CustomDetailTableCell>
                                      <CustomDetailTableCell>
                                        <DescriptionDetails data-test-id="descTestId">{this.toggleDescription(rowOrder.tenant.email_address)}<ReadMoreDetails data-test-id = "descModalId" onClick={()=>this.handleDescription(rowOrder.tenant.email_address)}>{this.handleMoreDetails(rowOrder.tenant.email_address)}</ReadMoreDetails>
                                        </DescriptionDetails>
                                      </CustomDetailTableCell>
                                      <CustomDetailTextCell>{rowOrder.tenant.phone_number}</CustomDetailTextCell>
                                      <CustomDetailTableCell data-test-id="createTestId">{this.formatDate(rowOrder.tenant.lease_start_date)}</CustomDetailTableCell>
                                      <CustomDetailTableCell data-test-id="dueTestId">{this.formatDate(rowOrder.tenant.lease_end_date)}</CustomDetailTableCell>
                                      <TableCell>
                                        <MoreArrow>
                                          <IconButton data-test-id="expandClickTestId" onClick={() => this.handleExpandClick(rowOrder.tenant.id)}>
                                            {this.state.expanded === rowOrder.tenant.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                          </IconButton>
                                        </MoreArrow>
                                      </TableCell>
                                    </TableRow>
                                    {this.state.expanded === rowOrder.tenant.id && (
                                      <TableRow>
                                        <TableCell colSpan={11}>
                                          <InnerTableBg>
                                            <AccordionDetails sx={{ backgroundColor:"#F3F2F0", borderRadius:"8px"}}>
                                                                <Grid container>
                                                                  <Grid item md={12}>
                                                                    <Grid container justifyContent="center">
                                                                      <Grid item md={11}>
                                                                        <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "0.5rem", marginTop:"32px", width:"103%" }}>
                                                                          <Box data-test-id="Updateinformation" sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "0.5rem", cursor: "pointer" }}>
                                                                            <img src={pencil} style={{ mixBlendMode:"multiply"}} alt="pencil-icon"/>
                                                                            <Typography style={webStyle.pencilStyle}>Update Tenant Information</Typography>
                                                                          </Box>
                                                                        </Box>

                                                                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                                          <Box sx={{ paddingLeft: "1rem" }}>
                                                                            <Typography style={webStyle.insuRance}>Insurance</Typography>
                                                                          </Box>
                                                                        </Box>

                                                                        <TableContainer >
                                                                          <Table aria-label="custom table">
                                                                            <TableHead>
                                                                              <TableRow>
                                                                                <TableCell style={webStyle.borderNoneStyleone}>Name</TableCell>
                                                                                <TableCell style={webStyle.borderNoneStyleone}>Uploaded by</TableCell>
                                                                                <TableCell style={webStyle.borderNoneStyleone}>Upload Date</TableCell>
                                                                                <TableCell style={webStyle.borderNoneStyleone}>Renewal date</TableCell>
                                                                              </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                              <TableRow>
                                                                                <TableCell style={webStyle.borderNoneStyleIns}>
                                                                                  <img src={imageIcon} alt="Placeholder" style={{ width: "24px", height: "24px", mixBlendMode: "multiply" }} />
                                                                                  <Typography style={webStyle.borderStyleTwo}>Image.jpeg(static image)</Typography>
                                                                                </TableCell>
                                                                                <TableCell style={webStyle.borderNoneStyle}>Liat Arama(static name)</TableCell>
                                                                                <TableCell style={webStyle.borderNoneStyle}>09 Dec 2023(static date)</TableCell>
                                                                                <TableCell style={webStyle.borderNoneStyle}>{this.formatDate(rowOrder.tenant.renewal_date)}</TableCell>
                                                                                <TableCell style={webStyle.downStyleload}>Download(static)</TableCell>
                                                                                <TableCell style={webStyle.downStyleload}>Preview(static)</TableCell>
                                                                              </TableRow>
                                                                            </TableBody>
                                                                          </Table>
                                                                        </TableContainer>

                                                                        <Box sx={{ marginTop: '2.1rem' }}>
                                                                          <Typography sx={{ paddingLeft: "1rem", ...webStyle.insuRance }}>Vehicles</Typography>
                                                                        </Box>

                                                                        <Grid container style={{ paddingLeft: "1rem" }}>
                                                                          <Grid item md={3} xs={3}>
                                                                            <Typography style={webStyle.borderNoneStylethree}>Class of vehicle</Typography>
                                                                            <Typography style={webStyle.borderNoneStylethree}>Brand</Typography>
                                                                            <Typography style={webStyle.borderNoneStylethree}>Model</Typography>
                                                                            <Typography style={webStyle.borderNoneStylethree}>Vehicle registration plate</Typography>
                                                                          </Grid>
                                                                          <Grid item md={7} xs={7}>
                                                                            <Typography style={webStyle.borderNoneStyleTwo}>{rowOrder.tenant.class_of_vehicle}</Typography>
                                                                            <Typography style={webStyle.borderNoneStyleTwo}>{rowOrder.tenant.brand_name}</Typography>
                                                                            <Typography style={webStyle.borderNoneStyleTwo}>{rowOrder.tenant.model}</Typography>
                                                                            <Typography style={webStyle.borderNoneStyleTwo}>{rowOrder.tenant.vehicle_registration_plate}</Typography>
                                                                          </Grid>
                                                                        </Grid>

                                                                        <Box sx={{ marginTop: '4rem' }}>
                                                                          <Typography sx={{ paddingLeft: "1rem", ...webStyle.insuRance }}>Pets</Typography>
                                                                        </Box>

                                                                        <Grid container style={{ paddingLeft: "1rem", marginBottom: "40px" }}>
                                                                          <Grid item md={3} xs={3}>
                                                                            <Typography style={webStyle.borderNoneStylethree}>Type Of Pet</Typography>
                                                                            <Typography style={webStyle.borderNoneStylethree}>Pet Name</Typography>
                                                                            <Typography style={webStyle.borderNoneStylethree}>Age</Typography>
                                                                            <Typography style={webStyle.borderNoneStylethree}>Pet ID</Typography>
                                                                          </Grid>
                                                                          <Grid item md={7} xs={7}>
                                                                            <Typography style={webStyle.borderNoneStyleTwo}>{rowOrder.tenant.type_of_pet}</Typography>
                                                                            <Typography style={webStyle.borderNoneStyleTwo}>{rowOrder.tenant.pet_name}</Typography>
                                                                            <Typography style={webStyle.borderNoneStyleTwo}>{rowOrder.tenant.pet_age}</Typography>
                                                                            <div style={{ display: "flex", gap: "30px" }}>
                                                                              <div style={webStyle.downloadImg}>
                                                                                <img src={imageIcon} alt="Placeholder" style={{ width: "24px", height: "24px", mixBlendMode: "multiply" }} />
                                                                                <Typography style={webStyle.borderStyleTwo}>Image.jpeg(static image)</Typography>
                                                                              </div>
                                                                              <Typography style={webStyle.petStyle}>Download</Typography>
                                                                              <Typography style={webStyle.petStyle}>Preview</Typography>
                                                                            </div>
                                                                          </Grid>
                                                                        </Grid>
                                                                      </Grid>
                                                                    </Grid>
                                                                  </Grid>
                                                                </Grid>
                                            </AccordionDetails>
                                          </InnerTableBg>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </TableBody>
                            <TableRow className={this.handleEmptyContainer(unitDetails.landlord_work_orders)}>
                                  <TableCell colSpan={11} align="center">
                                    No Tenant Found
                                  </TableCell>
                                </TableRow>
                          </Table>
                        </TableContainer>
                      </BorderContainer>
                    ))}
                  </React.Fragment>
                ))}
                    
                    <Menu
                      data-test-id="menuId"
                      id="property-menu"
                      anchorEl={this.state.anchorEl}
                      keepMounted
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.handleClose}
                      elevation={0}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                      }}
                      slotProps={{
                        paper: {
                          style: { width: "200px", height: "200px", boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px", marginLeft: "-2rem", marginTop: "0.5rem" }
                        }
                      }}
                    >
                      {this.handlePropertyData().map((property: any, propIndex: number) => (
                        <CustomMenuItem
                          data-test-id="propertyMenuId"
                          key={property.attributes.property_name}
                          onClick={(propevent) => this.handlePropertyClick(propevent, property, propIndex)}
                          className={this.handleChangeColor(propIndex)}
                        >
                          <CustomListItemText primaryTypographyProps={{ fontSize: "14px", whiteSpace: "nowrap",
                          overflow: "hidden", textOverflow: "ellipsis", width: "135px" }} primary={property.attributes.property_name} />
                          <KeyboardArrowRightIcon />
                        </CustomMenuItem>
                      ))}

                      {this.handlePropertySlice() && (
                        <CustomMenuItem
                          data-test-id="seeAllId"
                          key="seeAll"
                          onClick={this.toggleShowAll}
                        >
                          <CustomListColor primaryTypographyProps={{ fontSize: "14px" }} primary="See all" />
                        </CustomMenuItem>
                      )}
                    </Menu>
                    {this.state.selectedProperty && (
                      <Menu
                        data-test-id="menuItemId"
                        anchorEl={this.state.subMenuAnchorEl}
                        keepMounted
                        open={Boolean(this.state.subMenuAnchorEl)}
                        onClose={this.handleCloseMenu}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        slotProps={{
                          paper: {
                            style: { width: "120px", marginLeft: "10px" }
                          }
                        }}
                      >
                        {this.state.selectedProperty.attributes.units && this.state.selectedProperty.attributes.units.length > 0 && (
                          this.state.selectedProperty.attributes.units.map((unit: any) => (
                            <UnitMenuItem
                              data-test-id="unitMenuId"
                              key={unit.id}
                              onClick={() => this.handleUnitClick(unit.unit_name, unit.id)}
                            >
                              <CustomListItemText primaryTypographyProps={{
                                fontSize: "14px", whiteSpace: "nowrap",
                                overflow: "hidden", width: "100px", textOverflow: "ellipsis",
                              }} primary={unit.unit_name} />
                            </UnitMenuItem>
                          ))
                        )}
                      </Menu>
                    )}
                  </Box>
                  <Box className={this.handleHideDetails()}>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mt={10} className={this.handleEmptyProperty(this.state.properties)}>
                      {configJSON.noProperty}
                    </Box>
                  </Box>
                </WorkStyles>
            </MainWorkContainer> 
            <Box className="bgImgWrapper" data-test-id="bgImgId">
              <Box className="backgroundImage">
                <img src={hexagon} alt="hexagon-one" className="hexImg" />
              </Box>
              <Box className="backgroundImage">
                <img src={hexagon} alt="hexagon-two" className="hexImg" />
              </Box>
              <Box className="backgroundImage">
                <img src={hexagon} alt="hexagon-three" className="hexImg" />
              </Box>
              <Box className="backgroundImage">
                <img src={halfHexagon} alt="half-hexagon" className="hexImg" />
              </Box>
            </Box>
          
          </WorkArea>

        <React.Fragment>
        
          <Dialog
          data-test-id="openDialog"
            open={this.state.isOpenDialog}
            onClose={this.handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                borderRadius: '8px 8px 32px 8px',
              },
            }}
          >
             <DialogContent >
              <Box data-test-id="closeIcon" sx={{display: "flex", justifyContent: "right", alignItems: "right",cursor: 'pointer',marginBottom:"0.6rem"}} onClick={this.handleCloseopopup}>
                <CloseIcon/>
              </Box>
              {this.state.keyData.map((keydatas: PropertyData) => {
                return (
                  <BoxEditStyle style={{ gap: "10px", marginTop: "3%", marginBottom: "10%"}}>
                  <Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "0.5rem", }}>
                    <img src={pencil}/>
                    <Typography style={webStyle.pencilStyle}>Edit</Typography>
                    </Box>
                 
                    <Typography style={webStyle.KeyslocatedmaiBox}>Keys located in the mailbox,enter code #{keydatas.attributes.code}. Keep door closed at all times.</Typography>
                    <Box>
                    <Box>
                        <Typography style={webStyle.keyLocation}>Keys Location </Typography>
                        <Typography style={webStyle.keyLocation1}>Floor: {keydatas.attributes.floor}</Typography>
                        <Typography style={webStyle.keyLocation1}>Entrance: {keydatas.attributes.entrance}</Typography>
                        <Typography style={webStyle.keyLocation1}>Code #{keydatas.attributes.code}</Typography>
                      </Box>
               </Box>
                  </Box>
  
                  <BoxImage>
                    <img src = {dialogDialogimage} alt="edit"/>
                  </BoxImage>
  
                  
                 
                </BoxEditStyle>
                )
              })}
             
           
              <Box>
            
              </Box>
            </DialogContent>

          </Dialog>
        </React.Fragment>

        <React.Fragment>
          <Dialog
                maxWidth={false}
                sx={{
                  "& .MuiDialog-paper": {
                    width: "100%",
                    maxWidth: "1151px",
                  },
                }}
              open={this.state.isOpen}
              onClose={this.handleClose}
              aria-labelledby="responsive-dialog-title"
          >
              <DialogTitle id="responsive-dialog-title">
                <img aria-label="close"
                    onClick={this.handleClose}
                    data-test-id= 'close-dialog'
                    style={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                    }}
                    src={closeImage} 
                    />
              </DialogTitle>

              {this.state.propertiInfoData.map((infoData: PropertyData) => {
                const yearBuild = this.formatYear(infoData.attributes.year_built);
                const startDate = this.formatDate(infoData.attributes.management_start);
                const renewalDate = this.formatDate(infoData.attributes.renewal_date);
                const insuranceExp = this.formatDate(infoData.attributes.insurance_expiration);
                return (
                  <DialogContent sx={{ background: "#F8FAFC" }}>
                <PortfoliyoStyle style={{ border: "1px solid #BAB1AB", padding: "20px", borderRadius: "8px", marginTop: "50px"}}>
                  <Grid container>
                    <Grid item md={6} xs={12}>
                    <Grid container>
                        <Grid item md={9} xs={6}>
                            <Box>
                                <Typography data-test-id="heading-data" style={webStyle.modalTitleName}>Hilltop Apartments | Unit 1</Typography>
                            </Box>

                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Box style={webStyle.titleStyleContent}>
                              <Box style={webStyle.pencilEdit} data-test-id="handlepenciledit">
                                <img src={pencil} alt="edit"/>
                                <Typography style={webStyle.edittextData}>Edit</Typography>
                              </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item md={4} xs={6}>
                          <Typography style={webStyle.modalKeyStyle}>Property type</Typography>
                          <Typography style={webStyle.modalKeyStyle}>Address</Typography>
                          <Typography style={webStyle.modalKeyStyle}>Site manager</Typography>
                      </Grid>
                      <Grid item md={8} xs={6}>
                          <Typography style={webStyle.modalValueStyle}>{infoData.attributes.property_type}</Typography>
                          <Typography style={webStyle.modalValueStyle}>{infoData.attributes.address === null ? ("--") : infoData.attributes.address}</Typography>
                          <Typography style={webStyle.modalValueStyle}>{`${infoData.attributes.site_manager_prefix} ${infoData.attributes.site_manager_first_name} ${infoData.attributes.site_manager_last_name}`}</Typography>
                      </Grid>
                    </Grid>
                    </Grid>
                    <Grid className="sliderContainer" item md={6} xs={12}>
                        <div className="slider-container">
                          <Slider {...settings}>
                            <div>
                              <img src={imageOne}/>
                            </div>
                            <div>
                              <img src={imageTwo}/>
                            </div>
                            <div>
                              <img src={imageThree}/>
                            </div>
                            <div>
                              <img src={imageTwo}/>
                            </div>
                          </Slider>
                        </div>
                    </Grid>
                  </Grid>
                </PortfoliyoStyle>
                  <Grid container  >
                      <Grid item md={6}
                          sx={{
                            padding: "1rem",
                            "@media (max-width: 600px)": {
                                padding: 0,
                            }
                          }}
                      >
                          <Grid container style={{ marginTop: "2rem" }} >
                            <Grid item md={6} xs={6}>
                                <Box>
                                    <Typography style={webStyle.modalTitleName}>Property Information</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <Box style={webStyle.titleStyleContent}>
                                  <IconButton style={webStyle.editContent} size="small">
                                    <img src={pencil} alt="pencil-icon-data"/>
                                    <Typography style={webStyle.edittextData}>Edit</Typography>
                                  </IconButton>
                                </Box>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item md={4} xs={6}>
                                <Typography style={webStyle.modalKeyStyle}>Description</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Portfolio</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Site manager</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Year built</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Class</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Management start date</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Management end date</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Management end reason</Typography>
                                <Typography style={webStyle.modalKeyStyle}>FolioGuard Policy</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Rental license</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Renewal date</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Additional Interest</Typography>
                            </Grid>
                            <Grid item md={8} xs={6}>
                                <Typography style={webStyle.modalValueStyle}>{infoData.attributes.description}</Typography>
                                <Typography style={webStyle.modalValueStyle}>{infoData.attributes.portfolio}</Typography>
                                <Typography style={webStyle.modalValueStyle}>{`${infoData.attributes.site_manager_prefix} ${infoData.attributes.site_manager_first_name} ${infoData.attributes.site_manager_last_name}`}</Typography>
                                <Typography style={webStyle.modalValueStyle}>{yearBuild}</Typography>
                                <Typography style={webStyle.modalValueStyle}>{infoData.attributes.property_class}</Typography>
                                <Typography style={webStyle.modalValueStyle}>{startDate}</Typography>
                                <Typography style={webStyle.modalValueStyle}>09 Dec 2023</Typography>
                                <Typography style={webStyle.modalValueStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography>
                                <Typography style={webStyle.modalValueStyle}>{this.handleValue(infoData?.attributes?.folio_guard_policy)}</Typography>
                                <Typography style={webStyle.modalValueStyle}>{this.handleValue(infoData?.attributes?.rental_license)}</Typography>
                                <Typography style={webStyle.modalValueStyle}>{renewalDate}</Typography>
                            </Grid>
                          </Grid>


                          <Grid container style={{ marginTop: "2rem" }}>
                              <Grid item md={6} xs={6}>
                                <Box>
                                    <Typography style={webStyle.modalTitleName}>Maintenance Information</Typography>
                                </Box>
                              </Grid>
                              <Grid item md={6} xs={6}>
                                  <Box style={webStyle.titleStyleContent}>
                                    <IconButton size="small" style={webStyle.editContentInfo}>
                                    <img src={pencil} alt="pencil-data"/>
                                    <Typography style={webStyle.edittextDataInfo}>Edit</Typography>
                                    </IconButton>
                                  </Box>
                              </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                              <Grid item md={4} xs={6}>
                                  <Typography style={webStyle.modalKeyStyle}>Maintenance limit</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Insurance Expiration</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Has home warranty coverage</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Unit entry pre-authorized</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Maintenance notes</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Online maintenance</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Request instructions</Typography>
                              </Grid>
                              <Grid item md={8} xs={6}>
                                  <Typography style={webStyle.modalValueStyle}>09 Dec 2023</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{insuranceExp}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{this.handleValue(infoData?.attributes?.warranty_coverage)}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{this.handleValue(infoData?.attributes?.unit_entry_pre_authorized)}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.maintenance_notes}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.maintenance_request}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>1989</Typography>
                              </Grid>
                          </Grid>

                          <Grid container style={{ marginTop: "2rem" }} >
                            <Grid item md={6} xs={6}>
                                <Box>
                                    <Typography style={webStyle.modalTitleName}>Vacancy Posting Contact Info</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <Box style={webStyle.titleStyleContent}>
                                  <IconButton size="small" style={webStyle.editContent}>
                                    <img src={pencil} alt="pencil-icon"/>
                                    <Typography style={webStyle.edittextData}>Edit</Typography>
                                  </IconButton>
                                </Box>
                            </Grid>
                          </Grid>

                          <Grid container spacing={1}>
                              <Grid item md={4} xs={6}>
                                  <Typography style={webStyle.modalKeyStyle}>Email address</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Phone</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Extension</Typography>
                              </Grid>
                              <Grid item md={8} xs={6}>
                                  <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.vacancy_posting_email_address}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.vacancy_posting_phone_number}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.vacancy_posting_area_code}</Typography>
                              </Grid>
                          </Grid>
                      </Grid>

                      <Grid item md={6}
                          sx={{
                            padding: "1rem",
                            "@media (max-width: 600px)": {
                                padding: 0,
                            }
                          }}
                      >
                          <Grid container style={{ marginTop: "2rem" }} >
                            <Grid item md={6} xs={6}>
                                <Box>
                                    <Typography style={webStyle.modalTitleName}>Amenities</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <Box style={webStyle.titleStyleContent}>
                                  <IconButton size="small" style={webStyle.editContent}>
                                    <img src={pencil} alt="pencilIcon"/>
                                    <Typography style={webStyle.edittextData}>Edit</Typography>
                                  </IconButton>
                                </Box>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item md={4} xs={6}>
                                <Typography style={webStyle.modalKeyStyle}>Cats Allowed</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Dogs Allowed</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Amenities</Typography>
                            </Grid>
                            <Grid item md={8} xs={6}>
                                <Typography style={webStyle.modalValueStyle}>{this.handleValue(infoData?.attributes?.cats_allowed)}</Typography>
                                <Typography style={webStyle.modalValueStyle}>{this.handleValue(infoData?.attributes?.dogs_allowed)}</Typography>
                                <Typography style={webStyle.modalValueStyle}>Code restricted access loby, Free WiFi, on-site laundry room</Typography>
                            </Grid>
                          </Grid>


                          <Grid container style={{ marginTop: "2rem" }}>
                              <Grid item md={6} xs={6}>
                                <Box>
                                    <Typography style={webStyle.modalTitleName}>Marketing information</Typography>
                                </Box>
                              </Grid>
                              <Grid item md={6} xs={6}>
                                  <Box style={webStyle.titleStyleContent}>
                                    <IconButton style={webStyle.editContent} size="small">
                                    <img src={pencil} alt="pencil"/>
                                    <Typography style={webStyle.edittextData}>Edit</Typography>
                                    </IconButton>
                                  </Box>
                              </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                              <Grid item md={4} xs={6}>
                                  <Typography style={webStyle.modalKeyStyle}>Property marketing name</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Marketing description</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Lising Type</Typography>
                              </Grid>
                              <Grid item md={8} xs={6}>
                                  <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.property_marketing_name}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.marketing_description}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.lising_type}</Typography>
                              </Grid>
                          </Grid>

                          <Grid container style={{ marginTop: "2rem" }} >
                            <Grid item md={6} xs={6}>
                                <Box>
                                    <Typography style={webStyle.modalTitleName}>Attachments</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <Box style={webStyle.titleStyleContent}>
                                  <IconButton size="small" style={webStyle.editContent}>
                                    <img src={pencil} alt="Pencil"/>
                                    <Typography style={webStyle.edittextData}>Edit</Typography>
                                  </IconButton>
                                </Box>
                            </Grid>

                            <Box >
                            <TableContainer >
                              <Table sx={{ borderCollapse: "collapse" }}>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell style={webStyle.nameliststyle}>Name</TableCell>
                                          <TableCell style={webStyle.nameliststyle}>Uploaded by</TableCell>
                                          <TableCell style={webStyle.nameliststyle}>Date</TableCell>
                                          <TableCell style={webStyle.nameliststyle}>Shared</TableCell>

                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {Array.from({ length: 5 }, (_, index) => (
                                      <TableRow key={index}>
                                        <TableCell sx={{ borderBottom: "none" }}>
                                          <img src={imageIcon} alt="Placeholder" /> Sample Name {index + 1}
                                        </TableCell>
                                        <TableCell style={webStyle.nameliststyle1}>Uploader {index + 1}</TableCell>
                                        <TableCell style={webStyle.nameliststyle1}>Date {index + 1}</TableCell>
                                        <TableCell style={webStyle.nameliststyle1}>Yes/No</TableCell>
                                        <TableCell style={webStyle.downloadstyle}>Download</TableCell>
                                        <TableCell style={webStyle.downloadstyle}>Preview</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                              </Table>
                            </TableContainer>
                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <Pagination count={8} page={currentPage} />
                              <Typography variant="body1" style={{ marginLeft: 10 }}>1 of 8</Typography>
                            </Box>
                          </Box>
                          </Grid>
                      </Grid>
                  </Grid>
              </DialogContent>
                )
              })}
          </Dialog>
        </React.Fragment>

        <React.Fragment>
          <Dialog
                maxWidth={false}
                sx={{
                  "& .MuiDialog-paper": {
                    width: "100%",
                    maxWidth: "25%",
                  },
                }}
              open={this.state.isUnitOpen}
              onClose={this.handleCloseUnit}
              aria-labelledby="responsive-dialog-title"
          >
              <DialogTitle id="responsive-dialog-title">
                <img aria-label="close"
                    data-test-id="close-btn-data"
                    onClick={this.handleCloseUnit}
                    style={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                    }}
                    src={closeImage} 
                    />
              </DialogTitle>

              <DialogContent sx={{ background: "#F8FAFC" }}>
                <Box style={{ padding: "20px", borderRadius: "8px"}}>
                  <PortfoliyoStyle>
                  <Typography style={{ fontSize:"18px"}}>Add unit name</Typography>
                  <Box style={{ marginTop: "17px"}}>
                    <Box>
                      <Typography style={{ fontSize:"14px"}}>Unit name</Typography>
                      <StyledTextFieldthree
                        variant="outlined"
                        className="textField"
                        data-test-id="field-value-unit"
                        fullWidth
                        size="small"
                        value={this.state.unitName}
                        onChange={(event) => {
                          this.onValueChange("unitName", event.target.value)
                        }}
                      />
                    </Box>
                    <Box style={{ marginTop:"22px"}}>
                      <Box style={{ borderRadius: '8px', position: 'relative', marginTop:"20px" }}>
                        <Typography style={{ fontSize:"14px"}}>Number of Occupants</Typography>
                        <StyledTextFieldthree
                          data-testId="field-value-occupants"
                          select
                          fullWidth
                          name="whichoneState"
                          InputLabelProps={{ shrink: false }}
                          value={this.state.maxOccupant}
                          onChange={(event) => this.onValueChange("maxOccupant",event.target.value)} 
                          style={{ paddingRight: '30px'}} 
                        >
                          {maxDatas.map((number: string) => (
                              <MenuItem key={number} value={number}>{number}</MenuItem>
                            ))}
                        </StyledTextFieldthree>
                        <img
                          style={{
                            position: 'absolute',
                            right: '13px',
                            top: '70%',
                            transform: 'translateY(-50%)',
                            pointerEvents: 'none',
                          }}
                          src={downArrow}
                          alt="down"
                        />
                      </Box>
                    </Box>
                    <Button
                      data-test-id="add-btn"
                      disabled={!this.state.unitName}
                        onClick={this.handleAdd}
                        style={{
                          width: "74px",
                          height: "35px",
                          fontFamily: "Outfit",
                          fontSize: "16px",
                          fontWeight: 700,
                          color: "#000000",
                          textAlign: "center",
                          backgroundColor: this.state.unitName ? "#fab03d" : "rgb(238, 228, 213)", 
                          borderRadius: "8px",
                          textTransform: "none",
                          cursor: this.state.unitName ? "pointer" : "not-allowed", 
                          marginLeft: "81%",
                          marginTop: "17px",
                        }}
                    >
                      Add
                    </Button>
                  </Box>
                 </PortfoliyoStyle>
                </Box>
              </DialogContent>
          </Dialog>
        </React.Fragment>
        </WorkOrderStyle>

    );
  }

}
const CustomGrid = styled(Grid)({
  display: "flex",
  justifyContent: "flex-end",
  gap: 1,
  "@media(max-width: 900px)": {
    justifyContent: "flex-start",
    gap: "10px"
  }
});
const FilterChips = styled(Box)({
  width: "fit-content",
  display: "flex",
  border: "1px solid #000000",
  fontFamily: "Outfit",
  borderRadius: "4px",
  padding: "1px 6px 1px 2px",
  alignItems: "center",
  gap: "5px",
  height: "fit-content",
  whiteSpace: "nowrap"
  });
  
  const FilterDetails = styled(Typography)({
    fontSize: "12px",
    fontWeight: 700,
    color: "#544B45",
    fontFamily: "Outfit"
  });
  
  const FilterClose = styled(CloseIcon)({
    fontFamily: "Outfit",
    color: "#544B45",
    cursor: "pointer"
  });
const FilterContainer = styled(Box)({
  fontFamily: "Outfit",
  width: "100%",
  padding: "10px 10px 10px 10px",
  overflow: "auto",
  display: "flex",
  gap: "1rem"
});
const WorkOrderHead = styled(Typography)({
  fontSize: "24px",
  fontFamily: "Outfit",
  fontWeight: 700,
  color: "#000000",
  marginBottom: "20px"
});
const MainWorkContainer = styled(Box)({
  width: "100%",
  display: "flex",
  fontFamily: "Outfit",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px 0px 0px 0px"

  }
});
const FilterWeb = styled(Box)({
  width: "90%",
  fontFamily: "Outfit",
  paddingRight: "20px",
  "@media(max-width: 992px)": {
    display: "none"
  }
});

const FilterMobile = styled(Box)({
  fontFamily: "Outfit",
  display: "none",
  "@media(max-width: 992px)": {
    display: "block",
    width: "93%",
    paddingLeft: "16px"
  }
});
const WorkStyles = styled(Box)({
  width: "90%",
  marginTop: "42px",
  fontFamily: "Outfit",
  "& .search-main-content": {
    display: "flex",
    fontFamily: "Outfit",
    gap: "1rem",
    "@media(max-width:600px)": {
      width: "100%",
      position: "relative",
      height: "100%",
      flexDirection: "row",
      display: "flex",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .search-contents": {
    border: "1px solid #CBD5E1",
    borderRadius: "50px",
    fontFamily: "Outfit",
    height: "44px",
    display: "flex",
    paddingLeft: "15px",
    alignItems: "center",
    gap: "13px",
  },
  "& .search-icons": {
    height: "24px",
    width: "24px"
  },
  "& .search-text": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#A3978F",
    fontFamily: "Outfit",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .search-inputs": {
    width: "100%",
    fontFamily: "Outfit",
  },
  "& .properties-dropdown": {
    width: "142px",
    height: "44px",
    fontFamily: "Outfit",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    borderRadius: "8px",
    padding: "2px 10px 2px 10px",
    justifyContent: "space-around",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      fontSize: "12px",
      width: "105px",
      height: "30px"
    }
  },
  "& .view-completed": {
    width: "180px",
    backgroundColor: "rgb(253,242,208)",
    height: "44px",
    display: "flex",
    fontFamily: "Outfit",
    alignItems: "center",
    padding: "2px 10px 2px 10px",
    borderRadius: "8px",
    justifyContent: "space-around",
    cursor: "pointer",
    "@media(max-width: 460px)": {
      fontSize: "12px",
      height: "35px",
      width: "100%"
    },
    "@media(max-width: 347px)": {
      padding: "2px 5px 2px 5px"
    }
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    width: "16px",
    height: "16px"
  },
  "& .text-data": {
    fontSize: "16px",
    fontFamily: "Outfit",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-data": {
    fontSize: "18px",
    color: "#CC9200",
    fontFamily: "Outfit",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-property": {
    backgroundColor: "#FFC123",
    width: "166px",
    height: "44px",
    display: "flex",
    alignItems: "center",

    borderRadius: "8px",
    justifyContent: "space-evenly",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  "& .button-content": {
    width: "120px",
    height: "32px",
    backgroundColor: "#FFF1CC",
    gap: "6px",
    color: "#000000",
    fontSize: "16px",
    fontWeight: 700,
    display: "flex",
    alignItems: "center !important",
    justifyContent: "center !important",
    borderRadius:"8px"
  },
  "& .filter-content": {
    display: "flex",
    fontFamily: "Outfit",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "20px",
    "@media(max-width: 992px)": {
    paddingRight: "0px",
    }
  },
  "& .filter": {
    display: "flex",
    fontFamily: "Outfit",
    alignItems: "center",
    gap: "15px",
  },
  "& .filter-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000",
    fontFamily: "Outfit",
    paddingRight: "10px",
    "@media(max-width: 992px)": {
      paddingRight: "0px"
    }
  },
  "& .clear-data": {
    fontSize: "12px",
    fontWeight: 700,
    fontFamily: "Outfit",
    whiteSpace: "nowrap",
    color: "#CC9200",
    cursor: "pointer",
    paddingLeft: "10px",
    "@media(max-width: 992px)": {
      paddingLeft: "0px"
    }
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});
const WorkArea = styled(Box)({
  marginLeft: "90px", 
  flexGrow: 1,
  overflowY: "auto",
  height: "100vh",
  "@media(max-width: 992px)": {
    marginLeft: "0px", 
    height: "auto",
    overflowY: "unset"
  }
});
const WorkOrderStyle = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  fontFamily: "Outfit",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "&.webStyle": {
    backgroundColor: "red"
  },
  "& .bgImgWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    width: "97%",
    marginTop: "2rem",
    fontFamily: "Outfit",
    "@media (max-width:1220px)": {
      width: "88%"
    },
    "@media (max-width:992px)": {
      display: "none",
    }
  },
  "& .backgroundImage": {
    width: "250px",
    "@media (max-width:1220px)": {
      width: "180px"
    },
  },
  "& .hexImg": {
    objectFit: "cover"
  }
});
const UnitMenuItem = styled(MenuItem)({
  fontWeight: 400,
  fontFamily: "Outfit",
  fontSize: "14px",
  "@media(max-width: 475px)": {
    width: "12px"
  }
});
const CustomListColor = styled(ListItemText)({
  color: "#CC9200",
  fontSize: "14px !important",
  fontFamily: "Outfit",
  fontWeight: 400
});
const CustomMenuItem = styled(MenuItem)({
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: "Outfit",
  color: "#000000",
  margin: "0px 5px 0px 5px",
  "@media(max-width: 475px)": {
    fontSize: "10px",
  },
  "&.changeColor": {
    backgroundColor: "#f3f2f0",
    fontFamily: "Outfit",
    borderRadius: "10px"
  }
});

const CustomListItemText = styled(ListItemText)({
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: "Outfit",
  "@media(max-width: 475px)": {
    width: "12px"
  }
});
const ParagraphContainer = styled(Box)({
  backgroundColor: "white",
  width: "30%",
  fontFamily: "Outfit",
  height: "250px",
  borderRadius: "8px 8px 30px 8px",
  lineHeight: "2rem",
  padding: "25px 20px 80px 20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "25px",
   "@media(max-width: 1260px)": {
    width: "50%"
  },
  "@media(max-width: 767px)": {
    width: "80%"
  },
  "@media(max-width: 600px)": {
    gap: "15px",
    height: "290px"
  },
  "@media(max-width: 475px)": {
    gap: "12px",
    height: "345px"
  },
  "@media(max-width: 466px)": {
    gap: "10px",
    height: "390px"
  },
  "@media(max-width: 400px)": {
    gap: "10px",
    height: "400px"
  }
});

const KeyHeading = styled(Typography)({
  color: "#CC9200",
  fontWeight: 700,
  fontFamily: "Outfit",
  fontSize: "20px",
  "@media(max-width: 467px)": {
    fontSize: "16px"
  },
  "@media(max-width: 360px)": {
    fontSize: "15px"
  }
});

const KeyLocation = styled(Typography)({
  color: "#0F172A",
  fontFamily: "Outfit",
  fontSize: "18px",
  fontWeight: 700
});

const KeyPara = styled(Typography)({
  fontFamily: "Outfit",
  color: "#0F172A",
  fontSize: "18px",
  fontWeight: 400
});

const KeyContainer = styled(Box)({
  fontFamily: "Outfit",
  display: "flex",
  "@media(max-width: 467px)": {
    flexDirection: "column",
    gap: "10px"
  },
  "& .properties-dropdown": {
    width: "142px",
    fontFamily: "Outfit",
    justifyContent: "space-around",
    height: "44px",
    backgroundColor: "rgb(253,242,208)",
    textTransform: "none",
    borderRadius: "8px",
    padding: "2px 10px 2px 10px",
    cursor: "pointer",
    color: "#000000",
    display: "flex",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      width: "105px",
      height: "30px",
      fontSize: "12px",
    }
  },
});

const CloseModal = styled(Box)({
  fontFamily: "Outfit",
  display: "flex",
  justifyContent: "flex-end",
  cursor: "pointer"
});
const CustomModal = styled(Modal)({
  display: "flex",
  fontFamily: "Outfit",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px"
});
const ImagesVideosFiles = styled(Box)({
  height: "100px",
  width: "100px"
});

const ImageFiles = styled('img')({
  height: "100%",
  width: "100%",
  objectFit: "contain"
});
const BulkUploadingFiles = styled(Grid)({
  width: "100%",
  display: "flex",
  fontFamily: "Outfit",
  flexDirection: "column",
  paddingTop: "20px",
  alignItems: "center",
  gap:"10px"
});
const Images = styled(Typography)({
  textAlign: "left",
  fontSize: "16px",
  fontFamily: "Outfit",
  fontWeight: 700,
  color: "#1C1917"
});
const StatusTableCell = styled(TableCell)({
  fontSize: "12px",
  fontFamily: "Outfit",
  fontWeight: 400,
  color: "#000000",
  textAlign: "center",
  whiteSpace: "nowrap"
});
const CustomNewMenuItem = styled(MenuItem)({
  fontSize: "12px",
  fontWeight: 400,
  fontFamily: "Outfit",
  color: "#000000",
  "&.changeColor": {
    backgroundColor: "rgb(250,227,162)",
    fontFamily: "Outfit",
    borderRadius: "10px"
  },
  "&:hover": {
    backgroundColor: "rgb(250,227,162)",
    color: "#000000"
  }
});
const ReadMoreDetails = styled(Typography)({
  fontSize: "12px",
  fontWeight: 700,
  color: "#CC9200",
  fontFamily: "Outfit",
  textDecoration: "underline",
  wordBreak: "break-word",
  cursor: "pointer",
  "&::first-letter": {
    textTransform: "capitalize",
  },
});
const CustomApproveGrid = styled(Grid)({
  display: "flex",
  fontFamily: "Outfit",
  justifyContent: "space-between",
  alignItems: "center",
  "@media(max-width: 900px)": {
    gap: "10px",
    justifyContent: "flex-start"
  }
});

const CustomTableCell = styled(TableCell)({
  color: "#A3978F",
  textAlign: "center",
  fontSize: "12px",
  fontFamily: "Outfit",
  fontWeight: 700,
  padding: "0px 10px 0px 10px !important",
  whiteSpace: "nowrap"
});

const DetailsTableCell = styled(TableCell)({
  color: "#A3978F",
  fontSize: "12px",
  textAlign: "center",
  fontFamily: "Outfit",
  fontWeight: 700,
  whiteSpace: "nowrap"
});

const CustomDetailTableCell = styled(TableCell)({
  fontSize: "12px",
  fontFamily: "Outfit",
  color: "#A3978F",
  fontWeight: 400,
  textAlign: "center"
});

const CustomDetailTableCellId = styled(TableCell)({
  fontSize: "12px",
  color: "#A3978F",
  fontFamily: "Outfit",
  fontWeight: 400,
  textAlign: "left"
});

const CustomDetailTextCell = styled(TableCell)({
  fontSize: "12px",
  color: "#A3978F",
  textAlign: "center",
  fontFamily: "Outfit",
  fontWeight: 400,
  '&::first-letter': {
    textTransform: 'capitalize',
  },
});

const MoreArrow = styled(Box)({
  display: "flex"
});

const OrderStatus = styled(Typography)({
  borderRadius: "50px",
  color: "#059669",
  fontSize: "9px",
  textAlign: "center",
  whiteSpace: "nowrap",
  fontFamily: "Outfit",
  padding: "10px 20px",
  width: "fit-content",
  fontWeight: 700
});

const DescriptionDetails = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#A3978F",
  fontFamily: "Outfit",
  wordBreak: "break-word",
  cursor: "pointer",
  "&::first-letter": {
    textTransform: "capitalize",
  },
});

const InnerTableBg = styled(Box)({
  border: "1px solid #F9FAFC",
  borderRadius: "10px",
  backgroundColor: "#F9FAFC",
  fontFamily: "Outfit",
  padding: "20px 0px 20px 20px"
});

const StatusTableCells = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 400,
  fontFamily: "Outfit",
  color: "#000000",
  textAlign: "center",
   "&::first-letter": {
    textTransform: "capitalize",
  },
});

const OngoingName = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  fontFamily: "Outfit",
  color: "#1C1917"
});

const CustomTableContainer = styled(TableBody)({
  backgroundColor: "#FFFFFF"
});
const BorderContainer = styled(Box)({
  border: "1px solid #FFD466",
  borderRadius: "8px 8px 32px 8px",
  padding: "1rem",
  marginTop: "1rem",
  fontFamily: "Outfit",
  display: "flex",
  flexDirection: "column",
  "& .scrollable-container": {
    height: "300px",
    fontFamily: "Outfit",
    overflowY: "auto"
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});

const OrdersProgress = styled(Button)({
  borderRadius: "40px",
  backgroundColor: "#D1FAE5",
  color: "#059669",
  fontFamily: "Outfit",
  fontSize: "12px",
  padding: "0px 8px !important",
  fontWeight: 700,
  "&:hover": {
    backgroundColor: "#D1FAE5",
    color: "#059669"
  }
});

const OrderUnitContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  fontFamily: "Outfit",
  alignItems: "center",
  gap: "20px"
});

const OrderUnitName = styled(Typography)({
  fontSize: "16px",
  fontFamily: "Outfit",
  fontWeight: 700,
  color: "#000000"
});

const FindKeyName = styled(Typography)({
  color: "#CC9200",
  fontSize: "14px",
  fontFamily: "Outfit",
  fontWeight: 700,
  cursor: "pointer"
});

const SelectedOrder = styled(Typography)({
  color: "#A3978F",
  fontFamily: "Outfit",
  fontSize: "14px",
  fontWeight: 700
});
const HeadName = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  color: "#000000",
  fontFamily: "Outfit",
   '&::first-letter': {
    textTransform: 'capitalize',
  },
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});

const MainHeadings = styled(Typography)({
  fontSize: "16px",
  color: "#000000",
  fontFamily: "Outfit",
  fontWeight: 400,
   '&::first-letter': {
    textTransform: 'capitalize',
  },
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});

const HeadItems = styled(Box)({
  display: "flex",
  fontFamily: "Outfit",
  gap: "10px"
});

const CompletedDetails = styled(Box)({
  display: "flex",
  gap: "30px",
  fontFamily: "Outfit",
  justifyContent: "flex-end",
  "@media(max-width: 460px)": {
    gap: "5px",
    width: "100%"
  }
});

const GridItem = styled(Grid)({
  width: "100%"
});
const webStyle = {
  customChips: {
    alignItems: "center",
    height: "26px",
    borderRadius: "4px",
    border: "1px solid black",
    display: "flex",
    padding: "1px 6px 1px 2px",
    gap: "8px"
},
  taatomChips: {
    alignItems: "center",
    height: "26px",
    borderRadius: "4px",
    border: "1px solid black",
    display: "flex",
    padding: "1px 6px 1px 2px",
    gap: "8px"
},
  gridStyle: {
    display: "flex", justifyContent: "center", marginLeft:"16px",
    cursor: "pointer"
  },
  popuptwo:{
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
     width: "192px",
    backgroundColor: "#ffffff",
    position: "absolute",
    top: "50%",
  left: "71%"
  },
  KeyslocatedmaiBox:{
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    fontfamily: "outfit",
    color: "#CC9200",
    marginTop:'1.4rem'
   
  },
  keyLocation:{
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    fontfamily: "outfit",
    color: "#000000",
    marginTop:'1rem'
  },
  keyLocation1:{
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "26px",
    fontfamily: "outfit",
    color: "#000000",
    marginTop:'1rem'
  },

  unittextStyle: {
    marginTop: '1rem',
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    fontfamily: "outfit",
  },
  sideSidepop: {


    position: "absolute",
    left: "100%",
    top: "70%",
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
    width: "80vw",  // Use viewport width for responsiveness
    maxWidth: "100px",  // Set a maximum width
    background: "red",
    transform: "translate(-50%, -50%)",
    padding: "1rem",


  },
  fontsizepoptextall: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    fontfamily: "outfit",


    color: '#CC9200'
  },
  fontsizepoptext: {

    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    fontfamily: "outfit",
    color: '#0F172A'
  },
  flexContectionC: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0.6rem",
    position: "relative"

  },
  flexContection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0.6rem",

  },
  dropdowncontainer: {
    position: "relative",
    backgroundColor: "#FFF1CC",
    borderRadius: "8px",
    padding: '0.5rem',
    cursor: "pointer",
    paddingLeft:"0.5rem",
    paddingRight:"0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  popup: {
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
    position: "absolute",
    top: "100%",
    left: 0,
    width: "192px",
    backgroundColor: "#ffffff",
    marginTop: "1rem",

    overflow: "hidden",
    whiteSpace: "nowrap",
    zIndex: 1,
    padding: "10px"
  },
  dropdowncontainerchild: {
    width: "136px",
    display: "flex",
    alignItems: "center",

    borderRadius: "8px",
    justifyContent: "space-around",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  statusStyle: {
    display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", paddingLeft: "1rem", paddingRight: "1rem"
  },
  statusName: {
    marginLeft: "70px",
    width: "5rem"
  },
  dateOfBirth: {
    marginLeft:"7%"
  },
  emailAdd: {
    marginLeft:"36px", marginRight: "-27px", width: "5rem"
  },
  phoneNumber: {
    marginLeft: "27px", marginRight: "32px"
  },
  leaseStart: {
    marginRight: "16px", marginLeft: "-13px"
  },
  leaseEnd: {
    marginLeft: "4px"
  },
  borderNoneStylemattie1: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontfamily: "outfit",
    border: "none",
    color: "#000000",
    marginTop: "1.5rem"
  },
  borderNoneStylemattie: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontfamily: "outfit",
    border: "none",
    color: "#000000",
    marginTop: "0.5rem"

  },
  borderNoneStyle: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontfamily: "outfit",
    border: "none",
    color: "#000000"
  },
  borderNoneStyleTwo: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontfamily: "outfit",
    border: "none",
    color: "#000000",
    marginTop: '1rem',
  },
  borderNoneStylethree: {
    marginTop: '1rem',
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontfamily: "outfit",
    border: "none",
    color: '#BAB1AB'
  },
  borderNoneStyleone: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontfamily: "outfit",
    border: "none",
    color: '#BAB1AB'
  },
  fontsizeStyles: {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "10px",
    fontfamily: "outfit",
    color: '#A3978F'

  },
  tablestylemy: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontfamily: "outfit",
    color: "#000000"

  },
  downStyleload: {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "10px",
    fontfamily: "outfit",
    color: '#CC9200',
    border: "none"

  },
  addUitStyle: {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "10px",
    fontfamily: "outfit",
    color: '#059669'

  },
  addUitStylefont: {
    fontSize: "10px",
    fontWeight: 700,
    lineHeight: "10px",
    fontfamily: "outfit",
    color: '#059669'

  },
  insuRance: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    fontfamily: "outfit",
    color: '#000000'
  },
  pencilStyle: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    fontfamily: "outfit",
    color: '#CC9200'

  },
  commontextSyle: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "10px",
    fontfamily: "outfit",
    color: '#CC9200'

  },
  modalTitleName: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 700,
    fontFamily: "Outfit, sans-serif",
},

nameliststyle: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Outfit, sans-serif",
    lineHeight: "12px",
    borderBottom: "none",
    color: "#BAB1AB"

},
nameliststyle1: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Outfit, sans-serif",
    lineHeight: "12px",
    borderBottom: "none",
    color: "#000000"

},
modalKeyStyle: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Outfit, sans-serif",
    lineHeight: "12px",
    margin: "8px 0",
    color: "#BAB1AB"
},
bordernonestyle: {
    borderBottom: "none",
    color: "#CC9200",
    fontSize: "12px",
    fontWight: 700,
    fontFamily: "outfit"

},
downloadstyle: {
    color: "#CC9200",
    fontSize: "12px",
    fontWight: 700,
    fontFamily: "outfit",
    borderBottom: "none",
},
modalValueStyle: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Outfit, sans-serif",
    lineHeight: "12px",
    margin: "8px 0",
    color: "#000000", 
},

inputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '14px',
    marginRight: '20px',
    border: '1px solid #CBD5E1',
    borderRadius: '98px',
    padding: '0 10px',
    height: '40px',
  },
  input: {
    border: 'none',
    outline: 'none',
    flex: 1,
    borderRadius: '98px',
    padding: '10px 0',
  },
  adornment: {
    marginRight: '10px',
  },
  titleStyleContent: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
},
edittextData: {
  cursor: "pointer",
  fontSize: "12px",
  fontWeight: 700,
  fontFamily: "outfit",
  lineHeight: "10px",
  color: "#CC9200"
},
editContent: {
  marginLeft: "auto"
},
pencilEdit: {
  display: "flex", gap: "0.3rem" 
},
editContentInfo: {
  marginLeft: "auto"
},
edittextDataInfo: {
  cursor: "pointer",
  fontSize: "12px",
  fontWeight: 700,
  fontFamily: "outfit",
  lineHeight: "10px",
  color: "#CC9200"
},
petStyle: {
  fontSize: "12px",
    fontWeight: 700,
    lineHeight: "10px",
    fontfamily: "outfit",
    color: '#CC9200',
    border: "none",
    marginTop: '1rem',
},
borderStyleTwo: {
  fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontfamily: "outfit",
    border: "none",
    color: "#000000"
},
downloadImg: {
  display: "flex", 
  gap:"8px",
  alignItems: "center", 
  justifyContent: "center", 
  marginTop: "7px"
},
borderNoneStyleIns: {
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "12px",
  fontfamily: "outfit",
  border: "none",
  color: "#000000",
  display: "flex", 
  alignItems: "center", 
  gap:"8px",
}
};



// Customizable Area End
