import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FormEvent } from "react";
import { AlertColor } from "@mui/material";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
interface Tenant {
  tenant:{
    id: number;
    name: string;
  }
}

interface WorkOrder {
  vendor_account_id: number;
  assigned_vendor: string;
}

interface Unit {
  id: number;
  unit_name: string;
  tenants: Tenant[],
  landlord_work_orders: WorkOrder[]
}

interface PropertyData {
  id: string;
  attributes: {
    property_name: string;
    units: Unit[]
  }
}

interface Property {
  property_id: string,
  unit_id: number,
  name: string,
  tenants: any[],
  vendors: any[]
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeVendorItem: string;
  openVendorDrawer: boolean;
  allProperties: any[];
  allContacts: any[];
  allLedgerTypes: any[];
  property: string;
  contact: string;
  ledgerType: string;
  amount: string;
  refNumber: string;
  date: Date | null;
  details: string;
  messageOpen: boolean;
  messageType: AlertColor;
  message: string;
  errors: {
    amount: boolean;
    refNumber: boolean;
  }
  selectedProperty: Property;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NewTransactionController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addTransactionApiId: string = "";
  getPropertiesApiCallId: string = "";
  getLedgerTypesApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      , getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeVendorItem: "Analytics",
      openVendorDrawer: false,
      allProperties: [],
      allContacts: [],
      allLedgerTypes: [],
      property: "",
      contact: "",
      ledgerType: "",
      amount: "",
      refNumber: "",
      date: null,
      details: "",
      messageOpen: false,
      messageType: "success",
      message: "",
      errors: {
        amount: false,
        refNumber: false,
      },
      selectedProperty: {
        property_id: "",
        unit_id: 0,
        name: "",
        tenants: [],
        vendors: []
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleFormSubmitResponse(message);
      this.handleGetPropertiesResponse(message);
      this.handleGetLedgerTypesResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getPropertiesData();
    this.getLedgerTypes();
  };

  handleVendoritemClick = (vendorKey: string) => {
    this.setState({ activeVendorItem: vendorKey }, () => {
      this.handleWorkOrderMenu();
    });
  };

  handleVendorDrawer = () => {
      this.setState({
          openVendorDrawer: !this.state.openVendorDrawer
      });
  };

  handleWorkOrderMenu = () => {
    const { activeVendorItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeVendorItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };

  handlePopupMessageClose = () => {
    this.setState({
      messageOpen: false,
    });
  };

  handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    } as unknown as Pick<S, keyof S>);

    if (name === "amount") {
      this.validateAmount(value);
    } else if (name === "refNumber") {
      this.validateRefNumber(value);
    } else if (name === "property") {
      const property = this.state.allProperties.find((prop) => prop.name === value);
      this.setState({ selectedProperty: property });
      this.manageContactsData(name, value);
    } else if (name === "ledgerType") {
      this.manageContactsData(name, value);
    }
  };

  handleDateChange = (key: keyof S) => (newValue: Date | null) => {
    this.setState({ [key]: newValue } as unknown as Pick<S, keyof S>);
  };

  validateAmount = (amount: string) => {
    const amountRegex = /^\d{1,10}(\.\d{0,2})?$/;
    if (!amountRegex.test(amount)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          amount: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          amount: false,
        },
      }));
    }
  };

  validateRefNumber = (refNumber: string) => {
    const refNumberRegex = /^[a-zA-Z0-9]{1,20}$/;
    if (!refNumberRegex.test(refNumber)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          refNumber: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          refNumber: false,
        },
      }));
    }
  };

  getHelperText = (field: string) => {
    const { errors } = this.state;

    if(field === "amount" && errors.amount) {
        return "Please enter a valid amount.";
    }
    if(field === "refNumber" && errors.refNumber) {
      return "Please enter a valid reference number.";
    }
    return null;
  };

  validateForm = () => {
    if (
      this.state.property == "" ||
      this.state.contact == "" ||
      this.state.ledgerType == "" ||
      this.state.errors.amount ||
      this.state.errors.refNumber 
    ) {
      return false;
    }
    return true
  };

  manageContactsData = (field: string, value: string) => {
    if(field === "property" && this.state.ledgerType !== "" || field === "ledgerType" && this.state.property !== ""){
      this.setState({ contact: "" });
      let contacts = [];
      if(field === "property"){
        const property = this.state.allProperties.find((prop) => prop.name === value);
        contacts = this.getContactsByLedgerType(property, this.state.ledgerType);
      }

      if(field === "ledgerType"){
        contacts = this.getContactsByLedgerType(this.state.selectedProperty, value);
      }
      const uniqueArray = contacts.filter((item: { id: any; name: any; }, index: any, self: any[]) =>
        index === self.findIndex((t) => t.id === item.id && t.name === item.name)
      );
      this.setState({ allContacts: uniqueArray });
    }
  };

  getContactsByLedgerType = (property: any, ledgerType?: string) => {
    return ledgerType === "Rent" || ledgerType === "Income" ? property.tenants : property.vendors;
  };

  getPropertiesData = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPropertiesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPropertiesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getLedgerTypes = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLedgerTypesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLedgerTypesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.validateForm()) {
      const header = {
        token: localStorage.getItem("authToken"),
      };
  
      const formData = new FormData();
      formData.append(
        "transaction[property_id]",
        this.state.selectedProperty.property_id
      );
      formData.append(
        "transaction[unit_id]",
        String(this.state.selectedProperty.unit_id)
      );
      if(this.state.ledgerType === "Rent" || this.state.ledgerType === "Income" ) {
        formData.append(
          "transaction[tenant_id]",
          String(this.state.contact)
        );
      } else {
        formData.append(
          "transaction[vendor_account_id]",
          String(this.state.contact)
        );
      };
      formData.append(
        "transaction[transaction_date]",
        this.state.date ? this.state.date.toISOString().split("T")[0] : ""
      )
      
      formData.append(
        "transaction[ledger_type]",
        this.state.ledgerType
      );
      formData.append(
        "transaction[amount]",
        String(this.state.amount)
      );
      formData.append("transaction[reference_number]", this.state.refNumber);
      formData.append("transaction[details]", this.state.details);
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.addTransactionApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addTransactionApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  handleGetPropertiesResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getPropertiesApiCallId) {
      if (responseJson?.data?.length) {
        let allProperties:object[] = [];

        responseJson.data.map((prop: PropertyData) => {
          const prop_id = prop.id;
          const propName = prop.attributes.property_name;
          let propertyArray:object[] = [];

          prop.attributes.units.forEach(unit => {
            const unit_id = unit.id;

            const tenants = unit.tenants.map(tenantObj => ({
              id: tenantObj.tenant.id,
              name: tenantObj.tenant.name,
            }));
            
            const vendors = unit.landlord_work_orders.map(workOrder => ({
              id: workOrder.vendor_account_id,
              name: workOrder.assigned_vendor,
            }));
            
            const propertyObject = {
              property_id: prop_id,
              unit_id: unit_id,
              name: propName + ' - ' + unit.unit_name,
              tenants: tenants,
              vendors: vendors
            };
            
            propertyArray.push(propertyObject);
          });
          allProperties = allProperties.concat(propertyArray);
        });
        this.setState({ allProperties: allProperties });
      } else {
        //Check Error Response
        if (
          responseJson.errors &&
          responseJson.errors.length > 0 &&
          responseJson.errors[0].token
        ) {
          this.setState({
            messageOpen: true,
            messageType: "error",
            message: "Session Expired, Please Log in again."
          });
        } else {
          this.setState({
            messageOpen: true,
            messageType: "error",
            message: "Error while fetching properties."
          });
        }
      }
    }
  };

  handleGetLedgerTypesResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getLedgerTypesApiCallId) {
      if (responseJson?.ledger_type?.length) {
        this.setState({ allLedgerTypes: responseJson?.ledger_type });
      } else {
        //Check Error Response
        if (
          responseJson.errors &&
          responseJson.errors.length > 0 &&
          responseJson.errors[0].token
        ) {
          this.setState({
            messageOpen: true,
            messageType: "error",
            message: "Session Expired, Please Log in again."
          });
        } else {
          this.setState({
            messageOpen: true,
            messageType: "error",
            message: "Error while fetching ledger types."
          });
        }
      }
    }
  };

  handleFormSubmitResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.addTransactionApiId) {
      if (
        responseJson &&
        !responseJson.errors &&
        responseJson.data &&
        responseJson.data.attributes
      ) {

        this.setState({
          messageOpen: true,
          messageType: "success",
          message: "Form submitted successfully."
        });
        const toSendMessage = new Message(getName(MessageEnum.NavigationMessage));
        toSendMessage.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "Analytics"
        );
        toSendMessage.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        setTimeout(() => {
          this.send(toSendMessage);
        }, 2000);
      } else {
        //Check Error Response
        if (
          responseJson.errors &&
          responseJson.errors.length > 0 &&
          responseJson.errors[0].token
        ) {
          this.setState({
            messageOpen: true,
            messageType: "error",
            message: "Session Expired, Please Log in again."
          });
        } else {
          this.setState({
            messageOpen: true,
            messageType: "error",
            message: "An error occurred while processing your request. Please try again later."
          });
        }
      }
    }
  };
  navigateToAppointments = () => {
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Analytics"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  };
  // Customizable Area End
}
