Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.btnExampleTitle = "Submit";
exports.invoiceValidation ="Please enter 6 Digit Invoice Number"
exports.enterInvoice="Invoice Number"
exports.invoiceNumber="Enter Invoice Number (for example 299206)"
exports.keyboardType="phone-pad"
exports.exampleAPiEndPoint = "https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_invoice/invoice/generate_invoice_pdf?invoice_number=";
exports.exampleAPiMethod = "GET";
exports.vendorProfile  = "Vendor Profile";
exports.vendorLabelProfile = "UserProfileBasicBlock";
exports.projects = "Project History";
exports.vendorProjects = "Tasks";
exports.getInvoiceListAPIEndPoint="/account_block/vendor_work_orders/sent_invoices";
exports.invoiceLable = "Sent Invoices";
exports.invoiceBlock="InvoiceBilling";
exports.ApprovedOwnersLable="Approved Owners"
exports.ApprovedOwnersBlock = "AccountGroups";
exports.chatLable="Chat with Keasy"
exports.chatBlock="vendorprofile"
// Customizable Area End