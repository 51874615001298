// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import LandlordFirstLogin from "../../blocks/customform/src/LandlordFirstLogin.web"
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import CustomForm from "../../blocks/customform/src/CustomForm.web"
import Automatedworkflow from "../../blocks/automatedworkflow/src/Automatedworkflow";
import Cfdocumentforwarding4 from "../../blocks/cfdocumentforwarding4/src/Cfdocumentforwarding4";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Videos from "../../blocks/videos/src/Videos";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import AutomaticFormCreation from "../../blocks/automaticformcreation/src/AutomaticFormCreation";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Notifications from "../../blocks/notifications/src/Notifications";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import GoogleCalendarSync from "../../blocks/googlecalendarsync/src/GoogleCalendarSync";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Cfinfobippayments from "../../blocks/cfinfobippayments/src/Cfinfobippayments";
import Cfkeasyaibot from "../../blocks/cfkeasyaibot/src/Cfkeasyaibot";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import OpenWorkOrder from "../../blocks/appointmentmanagement/src/OpenWorkOrder.web";
import UpdateWorkOrder from "../../blocks/appointmentmanagement/src/UpdateWorkOrder.web" ;
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import TenantDashboard from "../../blocks/dashboard/src/TenantDashboard.web";
import VendorDashboard from "../../blocks/dashboard/src/VendorDashboard.web";
import PortfolioManagement from "../../blocks/portfoliomanagement/src/PortfolioManagement.web";
import PortfolioNewpropertymanagement from "../../blocks/portfoliomanagement/src/PortfolioNewpropertymanagement.web";
import VendorDashboardEdit from "../../blocks/dashboard/src/VendorDashboardEdit.web"
import SelectUserProfile from "../../blocks/email-account-registration/src/SelectUserProfile.web";
import PasswordRecovery from "../../blocks/forgot-password/src/PasswordRecovery.web";


import VendorOnboarding from "../../blocks/accountgroups/src/VendorOnboarding.web";
import NewTenant from "../../blocks/portfoliomanagement/src/NewTenant.web";
import CustomisableUserProfile from "../../blocks//customisableuserprofiles/src/CustomisableUserProfiles.web";
import ServicerequestDashboard from "../../blocks/automatedworkflow/src/ServicerequestDashboard.web"
import NewTransaction from "../../blocks/analytics/src/NewTransaction.web";
import Login from "../../blocks/automaticreminders/src/Login.web";
import AutomaticReminder from "../../blocks/automaticreminders/src/Automaticreminders.web"
import AutomatedPrioritization from "../../blocks/automaticreminders/src/Automatedprioritisation.web"


const routeMap = {
  
  ServicerequestDashboard: {
    component: ServicerequestDashboard,
    path: "/servicerequestdashboard"
  },  
 
   VendorDashboardEdit: {
    component: VendorDashboardEdit,
    path: "/vendordashboardedit"
  },                                                     


 PortfolioNewpropertymanagement:{
 component:PortfolioNewpropertymanagement,
 path:"/PortfolioNewpropertymanagement"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
VendorOnboarding:{
  component:VendorOnboarding,
path:"/VendorOnboarding"},

BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
CustomForm:{
  component:CustomForm,
path:"/CustomForm"
},
LandlordFirstLogin:{
 component:LandlordFirstLogin,
path:"/LandlordFirstLogin"
},
Automatedworkflow:{
 component:Automatedworkflow,
path:"/Automatedworkflow"},
Cfdocumentforwarding4:{
 component:Cfdocumentforwarding4,
path:"/Cfdocumentforwarding4"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Videos:{
 component:Videos,
path:"/Videos"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
AutomaticFormCreation:{
 component:AutomaticFormCreation,
path:"/AutomaticFormCreation"},
Splashscreen:{
 component:Splashscreen,
path:"/",
exact: true,
},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
OpenWorkOrder:{
 component:OpenWorkOrder,
path:"/OpenWorkOrder"},
UpdateWorkOrder:{
 component:UpdateWorkOrder,
path:"/UpdateWorkOrder"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task/:orderId"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
GoogleCalendarSync:{
 component:GoogleCalendarSync,
path:"/GoogleCalendarSync"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Cfinfobippayments:{
 component:Cfinfobippayments,
path:"/Cfinfobippayments"},
Cfkeasyaibot:{
 component:Cfkeasyaibot,
path:"/Cfkeasyaibot"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PortfolioManagement:{
  component:PortfolioManagement,
 path:"/PortfolioManagement"},
 NewReminders: {
  component: Login,
  path: "/NewReminders/:propertyId/:unitId"
},
EditReminders: {
  component: Login,
  path: "/EditReminders/:reminderId"
},
NewReminder: {
  component: Login,
  path: "/NewReminder"
},
OpenReminders: {
  component: Login,
  path: "/OpenReminders/:reminderId"
},
AutomaticReminder:{
component: AutomaticReminder,
path:"/AutomaticReminder"},
AutomatedPrioritization:{
component: AutomatedPrioritization,
path:"/AutomatedPrioritization"},

  Home: {
    component: HomeScreen,
    path: '/home',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },

  Alert: {
    component: AlertBlock,
    path: "*/Alert",
    modal: true
  },
  Dashboard: {
    component: Dashboard,
    path: "/landlordashboard"
  },
  TenantDashboard: {
    component: TenantDashboard,
    path: "/tenantdashboard"
  },
  VendorDashboard: {
    component: VendorDashboard,
    path: "/vendordashboard"
  },
 
  SelectUserProfile: {
    component: SelectUserProfile,
    path: "/SelectUserProfile"
  },
  PasswordRecovery: {
    component: PasswordRecovery,
    path: "/PasswordRecovery"
  },
  NewTenant: {
    component: NewTenant,
    path: "/NewTenant/:navigationBarTitleText"
  },
  CustomisableUserProfile: {
    component: CustomisableUserProfile,
    path: "/CustomisableUserProfile/:formType"
  },
  NewTransaction: {
    component: NewTransaction,
    path: "/NewTransaction"
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

return (
  <BuilderProvider>
    <View style={{ height: '100vh', width: '100vw' }}>
      {WebRoutesGenerator({ routeMap })}
      <ModalContainer />
    </View>
  </BuilderProvider>
);
  }
}

export default App;