import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { SelectChangeEvent } from '@mui/material/Select'; 
import { number } from "yup";
import { getStorageData } from "../../../../packages/framework/src/Utilities";

interface FileWithPreview extends File {
  preview: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeStep: number;
  full_name: string;
  date_of_birth: Date | null;
  primary_contact_email:string;
  countryCode: number;
  secondary_countryCode: number;
  primary_contact_number:number;
  primary_contact_address:string;
  secondary_contact_name:string;
  secondary_contact_address:string;
  bank_account_number:string;
  secondary_contact_email:string;
  secondary_contact_number:number;
  bank_routing_number:string;
  bank_name:string;
  open:boolean;
  search:string,
  voided_check: FileWithPreview[];
  photo: FileWithPreview[];
  photoState:string,
  government_picture:  FileWithPreview[];
  primary_way_of_communication: string[];
  selectedOptions: { title: string }[];
  dateChange:boolean;
  accept: boolean;
  countries: Array<{ name: string; emoji_flag: string; country_code: string }>;
  property: Array<{ name: string}>;
  error: null,
  openLandlordDrawer: boolean;
  activeMainItem:string
  messagePopup: boolean;
  messageForPopup: string;
  isCountryDropdownActive: boolean;
  errors: {
    primary_contact_number: boolean;
    primary_contact_email: boolean;
    fullName:boolean;
    EmergencyName:boolean;
    secondary_contact_number:boolean;
    secondary_contact_email: boolean;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getContriesListApiCallId: string = "";
  getPropertyListApiCallId: string = "";
  getSettingsDataCallId:string="";
  updatedSettingsApiId:string="";
  async componentDidMount() {
    this.getSettingsData();
    this.getCountries();
    this.getProperty();
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Stat
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeStep: 0,
      countryCode: 0,
      secondary_countryCode:0,
      accept: false,
      countries: [],
      property:[],
      full_name:"",
      primary_contact_email:"",
      primary_contact_number: 0,
      primary_contact_address:"",
      secondary_contact_name:"",
      secondary_contact_email:"",
      secondary_contact_number:0,
      secondary_contact_address:"",
      bank_name:"",
      bank_routing_number:"",
      bank_account_number:"",
      date_of_birth: null,
      search:"",
      selectedOptions:[],
      voided_check: [],
      government_picture:[],
      photo:[],
      primary_way_of_communication: [],
      photoState:"",
      error: null,
      dateChange:false,
      openLandlordDrawer:false,
      activeMainItem: "Settings2",
      open:false,
      messagePopup: false,
      messageForPopup: "",
      isCountryDropdownActive: false,
      errors: {
        primary_contact_number: false,
        primary_contact_email: false,
        fullName:false,
        EmergencyName:false,
        secondary_contact_number:false,
        secondary_contact_email: false

      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
     
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    ); 
  
    if (apiRequestCallId === this.getSettingsDataCallId){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data && responseJson.data.length > 0 && responseJson.data[0].attributes) {          
        if (apiRequestCallId === this.getSettingsDataCallId) {  
          this.setState({
            full_name: responseJson.data[0].attributes.full_name|| '',
            date_of_birth:responseJson.data[0].attributes.date_of_birth,
            primary_contact_email: responseJson.data[0].attributes.primary_contact_email || '',
            countryCode:responseJson.data[0].attributes.primary_contact_number[0],
            primary_contact_number:responseJson.data[0].attributes.primary_contact_number[1],
            primary_contact_address: responseJson.data[0].attributes.primary_contact_address[0] || '',
            secondary_contact_name: responseJson.data[0].attributes.secondary_contact_name || '',
            secondary_contact_email: responseJson.data[0].attributes.secondary_contact_email || '',
            secondary_countryCode: parseInt(responseJson.data[0].attributes.secondary_contact_number[0], 10),
            secondary_contact_number:  parseInt(responseJson.data[0].attributes.secondary_contact_number[1], 10),
            secondary_contact_address: responseJson.data[0].attributes.primary_contact_address[0] || '',
            bank_name: responseJson.data[0].attributes.bank_name || '',
            bank_routing_number: responseJson.data[0].attributes.bank_routing_number || '',
            bank_account_number: responseJson.data[0].attributes.bank_account_number || '',
            primary_way_of_communication: responseJson.data[0].attributes.preferred_way_of_communication || [],
            accept: responseJson.data[0].attributes.accept || false,
            photoState:responseJson.data[0].attributes.photo
        });

        } 
       
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);
      }
     
    }
   
    if (apiRequestCallId === this.getContriesListApiCallId) {
      let countries = responseJson?.data?.map((item: any) => {
        return {
          name: item.attributes.name,
          emoji_flag: item.attributes.emoji_flag,
          country_code: item.attributes.country_code,
        };
      });
      
      this.setState({ countries }, () => {});
    } 
    if (apiRequestCallId === this.getPropertyListApiCallId) { 
      let property = responseJson?.data?.map((item: any) => {
       return {
        name: item.attributes.property_name,
      };
      });
      this.setState({ property }, () => {});
    } 
    if (apiRequestCallId === this.updatedSettingsApiId) { 
      if (!responseJson.errors && responseJson) {
        this.setState({
          messagePopup: true,
          messageForPopup: "Changes updated successfully"
        });
        setTimeout(() => {
          this.setState((prevState) => ({
            activeStep: prevState.activeStep - 1,
          }));
        }, 2000);
  
      } else if (responseJson && responseJson.errors) {
        this.setState({
          messagePopup: true,
          messageForPopup: responseJson.errors
        });
        
      }
    } 
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  disableNextButton(step: number) {
    switch (step) {
      case 0:
        if (
          this.state.full_name !== "" &&
          this.state.primary_contact_email !== "" &&
          this.state.primary_contact_address !== "" &&
          this.state.secondary_contact_name !== "" &&
          this.state.secondary_contact_email !== "" &&
          this.state.secondary_contact_address !== ""
        ) {
          return false;
        } else {
          return true;
        }
      case 1:
        if ( this.state.bank_name !== "" &&
             this.state.bank_account_number !== "" ) {
          return false;
        } else {
          return true;
        }
      default:
        return true;
    }
  }
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleIconClick = () => {
    this.setState({open: !this.state.open})
  };
  handlePopupClose = () => {
    this.setState({
      messagePopup: false
    });
  };
  handleCountryCodeDropdown = () => {
    this.setState({ isCountryDropdownActive: true })
  }

  handleCountryChange = (key: keyof S, value: any) => {
    this.setState({
      [key]: value,
      isCountryDropdownActive: false 
    } as Pick<S, keyof S>);
  }
handleDropdown = (event: SelectChangeEvent<string[]>) => {
  const selectedValues = event.target.value as string[];
  const selectedOptions = selectedValues
    .map((value: string) => {
      const option = this.state.property.find(option => option.name === value);
      return option ? { title: option.name } : undefined;
    })
    .filter((option): option is { title: string } => Boolean(option));

  this.setState({ selectedOptions });
};
handleRemove = () => {
  this.setState({ photo: [] });
};
  handleFilesChange = (name: string, files: FileWithPreview[]) => {
    this.setState({
      [name]: files,
    } as unknown as Pick<S, keyof S>,);
  };
  handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    } as unknown as Pick<S, keyof S>);
  };

  handleChange = (key: keyof S, value: any) => {
    if (key === "primary_contact_email") {
      this.validateEmail(key,value);
    } else if (key === "primary_contact_number") {
      this.validatePhone(key,value);
    } else if (key === "secondary_contact_number") {
      this.validatePhone(key,value);
    } else if (key === "secondary_contact_email") {
      this.validateEmail(key,value);
    } else if (key === "full_name") {
      this.validateFullName(value);
    } else if (key === "secondary_contact_name") {
      this.validateEmergencyName(value);
    } else if (key === "primary_contact_address" || key === "secondary_contact_address") {
      this.setState({
        [key]: value,
      } as Pick<S, keyof S>);
    } 
    this.setState({
      [key]: value,
    } as Pick<S, keyof S>);
  };
  getHelperText = (field: string) => {
    const { errors } = this.state;
    if (field === "fullName" && errors.fullName) {
      return "Name should only contain letters";
    }
    if (field === "EmergencyName" && errors.EmergencyName) {
      return "Name should only contain letters ";
    }
    if(field === "primary_contact_number" && errors.primary_contact_number) {
      return "Please enter a valid phone number.";
    }
    if (field === "secondary_contact_number" && errors.secondary_contact_number) {
      return "Please enter a valid secondary phone number.";
    }
    if(field === "email") {
      if(errors.primary_contact_email){
        return "Please enter a valid email address.";
      } else {
        return "example@example.com";
      }
    }
    if(field === "secondary_email") {
      if(errors.secondary_contact_email){
        return "Please enter a valid email address.";
      } else {
        return "example@example.com";
      }
    }
    return null;
  };
  validatePhone = (key: string,phone:string) => {
    const phoneRegex = /^\d{10}$/;  
    if (!phoneRegex.test(phone)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          [key]: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          [key]: false,
        },
      }));
    }
  }
  validateFullName = (fullName: string) => {
    const fullNameRegex = /^[a-zA-Z\s]*$/; 
    if (!fullNameRegex.test(fullName)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          fullName: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          fullName: false,
        },
      }));
    }
  };
  
  validateEmergencyName = (fullName: string) => {
    const fullNameRegex = /^[a-zA-Z\s]*$/; 
    if (!fullNameRegex.test(fullName)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          EmergencyName: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          EmergencyName: false,
        },
      }));
    }
  };
  validateEmail = (key: string,email:string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          [key]: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          [key]: false,
        },
      }));
    }
  };
  handlePreferredCommunicationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    this.setState((prevState) => {
      const newPreferredCommunication = [
        ...prevState.primary_way_of_communication,
      ];
      if (newPreferredCommunication.includes(value)) {
        const index = newPreferredCommunication.indexOf(value);
        newPreferredCommunication.splice(index, 1);
      } else {
        if (newPreferredCommunication.length < 2) {
          newPreferredCommunication.push(value);
        }
      }
      return {
        primary_way_of_communication: newPreferredCommunication,
      } as Pick<S, keyof S>;
    });
  };
  isCheckboxDisabled = (value: string) => {
    return (
      this.state.primary_way_of_communication.length >= 2 &&
      !this.state.primary_way_of_communication.includes(value)
    );
  };
  getCountries = async  () => {
    const token = await getStorageData("authToken")

    const header = {
      token:token,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContriesListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountriesAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCountriesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getProperty = async  () => {
    const token = await getStorageData("authToken")

    const header = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPropertyListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPropertyListAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPropertyListMethod
    );
 

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSettingsData = async () => {
    const token = await getStorageData("authToken")

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSettingsDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSettingsDataAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSettingsDataMethod
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleDelete = (optionToDelete: { title: string }) => () => {
    this.setState((prevState) => ({
      selectedOptions: prevState.selectedOptions.filter(
        (option) => option !== optionToDelete
      ),
    }));
  };
  handleDateChange = (key: keyof S) => (newValue: Date | null) => {
    this.setState({ 
      [key]: newValue, 
      dateChange: true 
    } as unknown as Pick<S, keyof S>);
  };
  handleClearAll = () => {
    this.setState({ selectedOptions: [] });
  };
  handleNext = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };
  handleUpdate = async(event: React.FormEvent<HTMLFormElement>) => {
    
    event?.preventDefault();
    const token = await getStorageData("authToken")
    const header = {
      token: token 
    };

    const formData = this.formDataAppend();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatedSettingsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setSettingsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.setSettingsMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  formDataAppend = () =>{
    const formData = new FormData();
    formData.append(
      "landlord_account[full_name]",
      this.state.full_name
    );
    if(this.state.photo[0]){
      formData.append("landlord_account[photo]", this.state.photo[0] as Blob);
    };
    if(this.state.government_picture[0]){
      formData.append("landlord_account[government_picture]", this.state.government_picture[0] as Blob);
    };

    if (this.state.dateChange && this.state.date_of_birth) {
      formData.append(
        "landlord_account[date_of_birth]",
        this.state.date_of_birth.toISOString().split("T")[0]
      );
    }
      formData.append(
      "landlord_account[primary_contact_email]",
      this.state.primary_contact_email
    );
    formData.append("landlord_account[primary_contact_number][]", this.state.countryCode.toString());
    formData.append("landlord_account[primary_contact_number][]", this.state.primary_contact_number.toString()); 
    formData.append(
      "landlord_account[primary_contact_address][]",
      this.state.primary_contact_address
    );
    formData.append(
      "landlord_account[secondary_contact_name]",
      this.state.secondary_contact_name
    );
   
   formData.append(
      "landlord_account[secondary_contact_email]",
      this.state.secondary_contact_email
    );
   
    formData.append("landlord_account[secondary_contact_number][]", this.state.secondary_countryCode.toString());
    formData.append("landlord_account[secondary_contact_number][]", this.state.secondary_contact_number.toString());    
    formData.append(
      "landlord_account[secondary_contact_address][]",
      this.state.secondary_contact_address
    );

    formData.append(
      "landlord_account[primary_way_of_communication]",
      JSON.stringify(this.state.primary_way_of_communication)
    );

    formData.append(
      "landlord_account[bank_name]",
      this.state.bank_name
    );
    formData.append("landlord_account[bank_account_number]",this.state.bank_account_number);
    formData.append("landlord_account[bank_routing_number]",this.state.bank_routing_number);
    if (this.state.voided_check[0]) {
    formData.append("landlord_account[voided_check]", this.state.voided_check[0] as Blob);
    }
    formData.append("landlord_account[accept]", this.state.accept ? "true" : "false");
    return formData;
  }
  handleMainItemClick = (labelKey: string) => {
    this.setState({ activeMainItem: labelKey }, () => {
      this.handleWorkOrderMenu();
    });
  };
  handleWorkOrderMenu = () => {
    const { activeMainItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeMainItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };
  handleLandlordDrawer = () => {
    this.setState({
      openLandlordDrawer: !this.state.openLandlordDrawer,
    });
  };
  // Customizable Area End
}
